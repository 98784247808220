import React, { useEffect, useState } from 'react'
import { useAuth } from '../oidc/AuthContext';
import getAdobeCustomer from './CustomerData';
import { Link, Navigate, useParams } from 'react-router-dom';
import { GrRefresh } from 'react-icons/gr'
import { MdArrowDropDown, MdArrowDropUp } from 'react-icons/md'
import { BiRightTopArrowCircle } from 'react-icons/bi'



function getEditionName(offerId) {
    const adobeEditions = [
        {
            "offerId": "65305410CA13A12",
            "editionName": "VIP 13 Select 3 Year Commit InDesign for teams (Multi EU Languages)"
        },
        {
            "offerId": "65304841CA13A12",
            "editionName": "VIP 13 Select 3 Year Commit Creative Cloud for teams All Apps with Adobe Stock (Multi EU Languages)"
        },
        {
            "offerId": "65305118CA03A12",
            "editionName": "VIP 3 Select Adobe XD für Teams (Multi European Languages)"
        },
        {
            "offerId": "65322717CA01A12",
            "editionName": "VIPe 1 Adobe Presenter Licensed for Enterprise (Multi European Languages)"
        },
        {
            "offerId": "65305302CA01A12",
            "editionName": "VIP 1 Adobe RoboHelp Office für Teams (Multi European Languages)"
        },
        {
            "offerId": "65323478CA02012",
            "editionName": "VIPe 2 Adobe Express für Enterprise (Multi European Languages)"
        },
        {
            "offerId": "65324883CA03A12",
            "editionName": "VIP 3 Adobe Dreamweaver Pro für Teams (EU English)"
        },
        {
            "offerId": "65322696CA04A12",
            "editionName": "VIPe 4 Adobe RoboHelp Office für Enterprise (EU Englisch)"
        },
        {
            "offerId": "65305414CA02A12",
            "editionName": "VIP 2 Select Adobe InDesign für Teams (EU English)"
        },
        {
            "offerId": "65324911CA01A12",
            "editionName": "VIP 1 Adobe Illustrator Pro für Teams (EU English)"
        },
        {
            "offerId": "65305285CA02A12",
            "editionName": "VIP 2 Select Adobe Stock für Teams Medium 40 Standard Assets (Multi European Languages)"
        },
        {
            "offerId": "65324933CA04012",
            "editionName": "Promo VIP 4 Adobe After Effects Pro für Teams (Multi European Languages)"
        },
        {
            "offerId": "65305183CA03A12",
            "editionName": "VIP 3 Select Adobe Illustrator für Teams (Multi European Languages)"
        },
        {
            "offerId": "65304754CA04A12",
            "editionName": "VIP 4 Select Adobe Captivate für Teams (Multi European Languages)"
        },
        {
            "offerId": "65305248CA13A12",
            "editionName": "VIP 13 3 Year Commit Adobe Stock for teams (Small) (Multi EU Languages)"
        },
        {
            "offerId": "65305253CA02A12",
            "editionName": "VIP 2 Select Adobe Stock für Teams Small 10 Standard Assets (EU English)"
        },
        {
            "offerId": "65324839CA01A12",
            "editionName": "VIPe 1 Adobe Dreamweaver Pro für Enterprise (Multi European Languages)"
        },
        {
            "offerId": "65305521CA01A12",
            "editionName": "VIP 1 Adobe Animate / Flash Professional für Teams (Multi European Languages)"
        },
        {
            "offerId": "65322437CA02A12",
            "editionName": "VIPe 2 Adobe RoboHelp Server für Enterprise (EU English)"
        },
        {
            "offerId": "65304607CA03A12",
            "editionName": "VIP 3 Select Adobe After Effects für Teams (EU English)"
        },
        {
            "offerId": "65317574CA04A12",
            "editionName": "VIP 4 Select Adobe Substance 3D Collection for Teams (Multi European Languages)"
        },
        {
            "offerId": "65324933CA02A12",
            "editionName": "VIP 2 Adobe After Effects Pro für Teams (Multi European Languages)"
        },
        {
            "offerId": "65324823CA03A12",
            "editionName": "VIPe 3 Adobe After Effects Pro für Enterprise (EU English)"
        },
        {
            "offerId": "65324884CA03012",
            "editionName": "Promo VIP 3 Dreamweaver Pro für Teams (Multi European Languages)"
        },
        {
            "offerId": "65324863CA12012",
            "editionName": "VIP 12 3 Year Commit Photoshop Pro for teams (Multi EU Languages)"
        },
        {
            "offerId": "65324779CA03012",
            "editionName": "Promo VIP 3 Adobe XD Pro für Teams (Multi European Languages)"
        },
        {
            "offerId": "65324902CA02A12",
            "editionName": "VIP 2 Adobe InCopy Pro für Teams (Multi European Languages)"
        },
        {
            "offerId": "65304841CA01A12",
            "editionName": "VIP 1 Adobe Creative Cloud für Teams All Apps mit 10 Adobe Stock Standard Assets (Multi European Languages)"
        },
        {
            "offerId": "65304717CA01A12",
            "editionName": "VIP 1 Adobe InCopy für Teams (Multi European Languages)"
        },
        {
            "offerId": "65322656CA02A12",
            "editionName": "VIPe 2 Adobe InDesign for Enterprise (Multi European Languages)"
        },
        {
            "offerId": "65324949CA03A12",
            "editionName": "VIPe 3 Adobe Premiere Pro - Pro für Enterprise (EU English)"
        },
        {
            "offerId": "65323478CA01012",
            "editionName": "VIPe 1 Adobe Express für Enterprise (Multi European Languages)"
        },
        {
            "offerId": "65324897CA03A12",
            "editionName": "VIP 3 Adobe Audition Pro für Teams (EU English)"
        },
        {
            "offerId": "65322708CA01A12",
            "editionName": "VIPe 1 Adobe Captivate für Enterprise (Multi European Languages)"
        },
        {
            "offerId": "65324884CA04012",
            "editionName": "Promo VIP 4 Dreamweaver Pro für Teams (Multi European Languages)"
        },
        {
            "offerId": "65324802CA04012",
            "editionName": "Promo VIP 4 Select Animate / Flash Professional Pro for teams (Multi EU Languages)"
        },
        {
            "offerId": "65324797CA03012",
            "editionName": "Promo VIP 3 Adobe Premiere Pro - Pro für Teams (EU Englisch)"
        },
        {
            "offerId": "65324938CA03A12",
            "editionName": "VIP 3 Adobe InDesign Pro für Teams (Multi European Languages)"
        },
        {
            "offerId": "65324942CA04A12",
            "editionName": "VIP 4 Adobe InDesign Pro für Teams (EU English)"
        },
        {
            "offerId": "65324938CA01012",
            "editionName": "Promo VIP 1 InDesign Pro for teams (Multi EU Languages)"
        },
        {
            "offerId": "65325064CA01A12",
            "editionName": "VIPe 1 Adobe Creative Cloud Pro für Enterprise (EU English)"
        },
        {
            "offerId": "65322444CA03A12",
            "editionName": "VIPe 3 Adobe Creative Cloud für Enterprise (Multi European Languages)"
        },
        {
            "offerId": "65322649CA03A12",
            "editionName": "VIPe 3 Acrobat Pro DC für Enterprise (EU English)"
        },
        {
            "offerId": "65304522CA02A12",
            "editionName": "VIP 2 Adobe Acrobat Pro für Teams (EU English)"
        },
        {
            "offerId": "65304607CA01A12",
            "editionName": "VIP 1 Adobe After Effects für Teams (EU English)"
        },
        {
            "offerId": "65322484CAT1A12",
            "editionName": "Tier 1 Acrobat Sign Solutions for Business, AWS (Multi EU Languages)"
        },
        {
            "offerId": "65322698CA04A12",
            "editionName": "VIPe 4 Adobe RoboHelp Office für Enterprise (Multi European Languages)"
        },
        {
            "offerId": "65322607CA12A12",
            "editionName": "VIP 12 Select 3 Year Commit Acrobat Standard DC for Enterprise (Multi EU Languages)"
        },
        {
            "offerId": "65324802CA04A12",
            "editionName": "VIP 4 Select Adobe Animate / Flash Professional Pro für Teams (Multi European Languages)"
        },
        {
            "offerId": "65322427CA04A12",
            "editionName": "VIPe 4 Adobe Audition für Enterprise (EU English)"
        },
        {
            "offerId": "65324933CA03012",
            "editionName": "Promo VIP 3 Adobe After Effects Pro für Teams (Multi European Languages)"
        },
        {
            "offerId": "65324826CA14A12",
            "editionName": "VIP 14 Select 3 Year Commit Illustrator Pro for Enterprise (Multi EU Languages)"
        },
        {
            "offerId": "65324910CA02A12",
            "editionName": "VIP 2 Adobe Illustrator Pro für Teams (Multi European Languages)"
        },
        {
            "offerId": "65305414CA03A12",
            "editionName": "VIP 3 Select Adobe InDesign für Teams (EU English)"
        },
        {
            "offerId": "65324833CA02A12",
            "editionName": "VIPe 2 Adobe XD Pro für Enterprise (Multi European Languages)"
        },
        {
            "offerId": "65324841CA02A12",
            "editionName": "VIPe 2 Adobe Dreamweaver Pro für Enterprise (EU English)"
        },
        {
            "offerId": "65304599CA04A12",
            "editionName": "VIP 4 Select Adobe After Effects für Teams (Multi European Languages)"
        },
        {
            "offerId": "65324859CA01A12",
            "editionName": "VIPe 1 Adobe Photoshop Pro für Enterprise (EU Englisch)"
        },
        {
            "offerId": "65304840CA03A12",
            "editionName": "VIP 3 Select Adobe Creative Cloud für Teams All Apps mit 10 Adobe Stock Standard Assets (EU English)"
        },
        {
            "offerId": "65304754CA02A12",
            "editionName": "VIP 2 Select Adobe Captivate für Teams (Multi European Languages)"
        },
        {
            "offerId": "65324910CA01012",
            "editionName": "Promo VIP 1 Adobe Illustrator Pro for Teams (Multi EU Languages)"
        },
        {
            "offerId": "65304599CA01A12",
            "editionName": "VIP 1 Adobe After Effects für Teams (Multi European Languages)"
        },
        {
            "offerId": "65324823CA01A12",
            "editionName": "VIPe 1 Adobe After Effects Pro für Enterprise (EU English)"
        },
        {
            "offerId": "65304918CA04A12",
            "editionName": "VIP 4 Select Adobe Dreamweaver für Teams (Multi European Languages)"
        },
        {
            "offerId": "65324915CA03A12",
            "editionName": "VIPe 3 Adobe Animate/Flash Professional Pro für Enterprise (Multi European Languages)"
        },
        {
            "offerId": "65322440CA01A12",
            "editionName": "VIPe 1 Adobe RoboHelp Server für Enterprise (Multi European Languages)"
        },
        {
            "offerId": "65324782CA04A12",
            "editionName": "VIP 4 Select Adobe XD Pro für Teams (EU English)"
        },
        {
            "offerId": "65304886CA02A12",
            "editionName": "VIP 2 Select Adobe Acrobat DC Standard für Teams (Multi European Languages)"
        },
        {
            "offerId": "65304840CA01A12",
            "editionName": "VIP 1 Adobe Creative Cloud für Teams All Apps mit 10 Adobe Stock Standard Assets (EU English)"
        },
        {
            "offerId": "65322619CA04A12",
            "editionName": "VIPe 4 Adobe After Effects für Enterprise (Multi European Languages)"
        },
        {
            "offerId": "65322717CA04A12",
            "editionName": "VIPe 4 Adobe Presenter Licensed for Enterprise (Multi European Languages)"
        },
        {
            "offerId": "65324945CA01A12",
            "editionName": "VIPe 1 Adobe Premiere Pro - Pro für Enterprise (Multi European Languages)"
        },
        {
            "offerId": "65322607CA02A12",
            "editionName": "VIPe 2 Adobe Acrobat DC Standard für Enterprise (Multi European Languages)"
        },
        {
            "offerId": "65322595CA03A12",
            "editionName": "VIPe 3 Adobe XD für Enterprise (Multi European Languages)"
        },
        {
            "offerId": "65322582CA03A12",
            "editionName": "VIPe 3 Adobe Animate/Flash Professional für Enterprise (Multi European Languages)"
        },
        {
            "offerId": "65317574CA13A12",
            "editionName": "VIP 13 Select 3 Year Commit Substance 3D Collection for Teams (Multi EU Languages)"
        },
        {
            "offerId": "65324903CA02A12",
            "editionName": "VIP 2 Adobe InCopy Pro für Teams (EU English)"
        },
        {
            "offerId": "65322653CA02A12",
            "editionName": "VIPe 2 Adobe InDesign for Enterprise (EU Englisch)"
        },
        {
            "offerId": "65324833CA03A12",
            "editionName": "VIPe 3 Adobe XD Pro für Enterprise (Multi European Languages)"
        },
        {
            "offerId": "65323043CA01A12",
            "editionName": "VIPe 1 Adobe InCopy für Enterprise (EU Englisch)"
        },
        {
            "offerId": "65304522CA03A12",
            "editionName": "VIP 3 Adobe Acrobat Pro für Teams (EU English)"
        },
        {
            "offerId": "65324771CA04012",
            "editionName": "Promo VIP 4 Lightroom Classic Pro for teams (Multi EU Languages)"
        },
        {
            "offerId": "65324897CA02012",
            "editionName": "Promo VIP 2 Audition Pro for Teams (EU English)"
        },
        {
            "offerId": "65305449CA01A12",
            "editionName": "VIP 1 Adobe Dimension für Teams (EU English)"
        },
        {
            "offerId": "65304741CA01A12",
            "editionName": "VIP 1 Adobe Captivate für Teams (EU English)"
        },
        {
            "offerId": "65324883CA04A12",
            "editionName": "VIP 4 Adobe Dreamweaver Pro für Teams (EU English)"
        },
        {
            "offerId": "65324933CA03A12",
            "editionName": "VIP 3 Adobe After Effects Pro für Teams (Multi European Languages)"
        },
        {
            "offerId": "65305063CA02A12",
            "editionName": "VIP 2 Select Adobe Fresco für Teams (Multi European Languages)"
        },
        {
            "offerId": "65324790CA03A12",
            "editionName": "VIPe 3 Adobe Dimension Pro für Enterprise (EU English)"
        },
        {
            "offerId": "65322484CAT3A12",
            "editionName": "Tier 3 Acrobat Sign Solutions for Business, AWS (Multi EU Languages)"
        },
        {
            "offerId": "65305253CA01A12",
            "editionName": "VIP 1 Adobe Stock für Teams Small 10 Standard Assets (EU English)"
        },
        {
            "offerId": "65324942CA02012",
            "editionName": "Promo VIP 2 InDesign Pro for teams (EU English)"
        },
        {
            "offerId": "65324938CA02012",
            "editionName": "Promo VIP 2 InDesign Pro for teams (Multi EU Languages)"
        },
        {
            "offerId": "65324790CA04A12",
            "editionName": "VIPe 4 Adobe Dimension Pro für Enterprise (EU English)"
        },
        {
            "offerId": "65304741CA04A12",
            "editionName": "VIP 4 Select Adobe Captivate für Teams (EU English)"
        },
        {
            "offerId": "65323053CA04A12",
            "editionName": "VIPe 4 Adobe Substance 3D Collection for Enterprise (EU Englisch)"
        },
        {
            "offerId": "65304841CA04A12",
            "editionName": "VIP 4 Select Adobe Creative Cloud für Teams All Apps mit 10 Adobe Stock Standard Assets (Multi European Languages)"
        },
        {
            "offerId": "65322619CA02A12",
            "editionName": "VIPe 2 Adobe After Effects für Enterprise (Multi European Languages)"
        },
        {
            "offerId": "65322581CA03A12",
            "editionName": "VIPe 3 Adobe Animate/Flash Professional für Enterprise (EU English)"
        },
        {
            "offerId": "65324884CA04A12",
            "editionName": "VIP 4 Adobe Dreamweaver Pro für Teams (Multi European Languages)"
        },
        {
            "offerId": "65322698CA03A12",
            "editionName": "VIPe 3 Adobe RoboHelp Office für Enterprise (Multi European Languages)"
        },
        {
            "offerId": "65324952CA04A12",
            "editionName": "VIPe 4 Adobe Dimension Pro für Enterprise (Multi EU Languages)"
        },
        {
            "offerId": "65324897CA03012",
            "editionName": "Promo VIP 3 Audition Pro for Teams (EU English)"
        },
        {
            "offerId": "65322565CA04A12",
            "editionName": "VIPe 4 Adobe Dimension für Enterprise (EU English)"
        },
        {
            "offerId": "65322644CA03A12",
            "editionName": "VIPe 3 Adobe Photoshop for Enterprise (EU Englisch)"
        },
        {
            "offerId": "65322417CA02A12",
            "editionName": "VIPe 2 Adobe Illustrator für Enterprise (EU Englisch)"
        },
        {
            "offerId": "65324785CA02A12",
            "editionName": "VIP 2 Adobe Lightroom Classic Pro für Teams (EU English)"
        },
        {
            "offerId": "65324952CA03A12",
            "editionName": "VIPe 3 Adobe Dimension Pro für Enterprise (Multi European Languages)"
        },
        {
            "offerId": "65324893CA02A12",
            "editionName": "VIPe 2 Adobe Audition Pro für Enterprise (Multi European Languages)"
        },
        {
            "offerId": "65322628CA01A12",
            "editionName": "VIPe 1 Adobe Lightroom Classic für Enterprise (Multi European Languages)"
        },
        {
            "offerId": "65322725CA04A12",
            "editionName": "VIPe 4 Adobe Technical Suite für Enterprise (Multi European Languages)"
        },
        {
            "offerId": "65322440CA02A12",
            "editionName": "VIPe 2 Adobe RoboHelp Server für Enterprise (Multi European Languages)"
        },
        {
            "offerId": "65324893CA03A12",
            "editionName": "VIPe 3 Adobe Audition Pro für Enterprise (Multi European Languages)"
        },
        {
            "offerId": "65324857CA13A12",
            "editionName": "VIP 13 Select 3 Year Commit Photoshop Pro for Enterprise (Multi EU Languages)"
        },
        {
            "offerId": "65322472CA03A12",
            "editionName": "VIPe 3 Adobe Premiere Pro für Enterprise (EU English)"
        },
        {
            "offerId": "65324875CA04A12",
            "editionName": "VIPe 4 Adobe InCopy Pro für Enterprise (Multi European Languages)"
        },
        {
            "offerId": "65322699CA01A12",
            "editionName": "VIPe 1 Adobe Framemaker für Enterprise (Multi European Languages)"
        },
        {
            "offerId": "65305345CA04A12",
            "editionName": "VIP 4 Select Adobe FrameMaker für Teams (EU English)"
        },
        {
            "offerId": "65323054CA03A12",
            "editionName": "VIPe 3 Adobe Substance 3D Collection für Enterprise (Multi European Languages)"
        },
        {
            "offerId": "65304482CA04A12",
            "editionName": "VIP 4 Select Adobe Audition für Teams (Multi European Languages)"
        },
        {
            "offerId": "65304886CA12A12",
            "editionName": "VIP 12 Select 3 Year Commit Acrobat Standard DC for teams (Multi EU Languages)"
        },
        {
            "offerId": "65324928CA01A12",
            "editionName": "VIPe 1 Adobe InDesign Pro für Enterprise (Multi European Languages)"
        },
        {
            "offerId": "65305596CA03A12",
            "editionName": "VIP 3 Select Adobe Acrobat Sign für SMB (EU English)"
        },
        {
            "offerId": "65324785CA03012",
            "editionName": "Promo VIP 3 Lightroom Classic Pro for teams (EU English)"
        },
        {
            "offerId": "65324901CA02A12",
            "editionName": "VIP 2 Adobe Audition Pro für Teams (Multi European Languages)"
        },
        {
            "offerId": "65304919CA04A12",
            "editionName": "VIP 4 Select Adobe Dreamweaver für Teams (EU English)"
        },
        {
            "offerId": "65304764CA01A12",
            "editionName": "VIP 1 Adobe Technical Suite für Teams (EU English)"
        },
        {
            "offerId": "65324910CA01A12",
            "editionName": "VIP 1 Adobe Illustrator Pro für Teams (Multi European Languages)"
        },
        {
            "offerId": "65324785CA04A12",
            "editionName": "VIP 4 Adobe Lightroom Classic Pro für Teams (EU English)"
        },
        {
            "offerId": "65324889CA02A12",
            "editionName": "VIPe 2 Adobe Audition Pro für Enterprise (EU English)"
        },
        {
            "offerId": "65305065CA01A12",
            "editionName": "VIP 1 Adobe Fresco für Teams (EU English)"
        },
        {
            "offerId": "65325070CA01A12",
            "editionName": "VIPe 1 Adobe Creative Cloud Pro für Enterprise (Multi European Languages)"
        },
        {
            "offerId": "65325084CA01012",
            "editionName": "Promo VIP 1 Adobe Creative Cloud Pro für Teams (EU English)"
        },
        {
            "offerId": "65305345CA02A12",
            "editionName": "VIP 2 Select Adobe FrameMaker für Teams (EU English)"
        },
        {
            "offerId": "65305285CA12A12",
            "editionName": "VIP 12 3 Year Commit Adobe Stock for teams (Medium) (Multi EU Languages)"
        },
        {
            "offerId": "65324828CA03A12",
            "editionName": "VIPe 3 Adobe Illustrator Pro für Enterprise (EU Englisch)"
        },
        {
            "offerId": "65322488CA02A12",
            "editionName": "VIPe 2 Adobe FrameMaker Publishing Server für Enterprise (Multi European Languages)"
        },
        {
            "offerId": "65324849CA03A12",
            "editionName": "VIP 3 Adobe Dimension Pro für Teams (EU English)"
        },
        {
            "offerId": "65305253CA03A12",
            "editionName": "VIP 3 Select Adobe Stock für Teams Small 10 Standard Assets (EU English)"
        },
        {
            "offerId": "65304579CA12A12",
            "editionName": "VIP 12 Select 3 Year Commit Creative Cloud for teams All Apps (Multi EU Languages)"
        },
        {
            "offerId": "65324916CA04A12",
            "editionName": "VIPe 4 Adobe Animate/Flash Professional Pro für Enterprise (EU English)"
        },
        {
            "offerId": "65324839CA02A12",
            "editionName": "VIPe 2 Adobe Dreamweaver Pro für Enterprise (Multi European Languages)"
        },
        {
            "offerId": "65304716CA02A12",
            "editionName": "VIP 2 Select Adobe InCopy für Teams (EU English)"
        },
        {
            "offerId": "65324942CA02A12",
            "editionName": "VIP 2 Adobe InDesign Pro für Teams (EU English)"
        },
        {
            "offerId": "65324883CA02012",
            "editionName": "Promo VIP 2 Dreamweaver Pro für Teams (EU English)"
        },
        {
            "offerId": "65324883CA01012",
            "editionName": "Promo VIP 1 Dreamweaver Pro für Teams (EU English)"
        },
        {
            "offerId": "65305370CA01A12",
            "editionName": "VIP 1 Adobe Premiere Pro für Teams (Multi European Languages)"
        },
        {
            "offerId": "65324883CA04012",
            "editionName": "Promo VIP 4 Dreamweaver Pro für Teams (EU English)"
        },
        {
            "offerId": "65322471CA02A12",
            "editionName": "VIPe 2 Adobe Premiere Pro für Enterprise (Multi European Languages)"
        },
        {
            "offerId": "65323045CA14A12",
            "editionName": "VIP 14 Select 3 Year Commit InCopy for Enterprise (Multi EU Languages)"
        },
        {
            "offerId": "65325070CA13A12",
            "editionName": "VIP 13 Select 3 Year Commit Creative Cloud Pro for Enterprise All Apps (Multi EU Languages)"
        },
        {
            "offerId": "65324878CA01A12",
            "editionName": "VIPe 1 Adobe InCopy Pro für Enterprise (EU Englisch)"
        },
        {
            "offerId": "65324779CA02A12",
            "editionName": "VIP 2 Select Adobe XD Pro für Teams (Multi European Languages)"
        },
        {
            "offerId": "65324779CA01A12",
            "editionName": "VIP 1 Adobe XD Pro für Teams (Multi European Languages)"
        },
        {
            "offerId": "65324828CA04A12",
            "editionName": "VIPe 4 Adobe Illustrator Pro für Enterprise (EU Englisch)"
        },
        {
            "offerId": "65322628CA02A12",
            "editionName": "VIPe 2 Adobe Lightroom Classic für Enterprise (Multi European Languages)"
        },
        {
            "offerId": "65324889CA01A12",
            "editionName": "VIPe 1 Adobe Audition Pro für Enterprise (EU English)"
        },
        {
            "offerId": "65305065CA04A12",
            "editionName": "VIP 4 Select Adobe Fresco für Teams (EU English)"
        },
        {
            "offerId": "65324863CA01A12",
            "editionName": "VIP 1 Adobe Photoshop Pro für Teams (Multi European Languages)"
        },
        {
            "offerId": "65305248CA01A12",
            "editionName": "VIP 1 Adobe Stock für Teams Small 10 Standard Assets (Multi European Languages)"
        },
        {
            "offerId": "65324779CA02012",
            "editionName": "Promo VIP 2 Adobe XD Pro für Teams (Multi European Languages)"
        },
        {
            "offerId": "65322505CA04A12",
            "editionName": "VIPe 4 Adobe Dreamweaver für Enterprise (EU English)"
        },
        {
            "offerId": "65304919CA01A12",
            "editionName": "VIP 1 Adobe Dreamweaver für Teams (EU English)"
        },
        {
            "offerId": "65305345CA03A12",
            "editionName": "VIP 3 Select Adobe FrameMaker für Teams (EU English)"
        },
        {
            "offerId": "65322603CA01A12",
            "editionName": "VIPe 1 Acrobat DC Standard  für Enterprise (EU English)"
        },
        {
            "offerId": "65324938CA03012",
            "editionName": "Promo VIP 3 InDesign Pro for teams (Multi EU Languages)"
        },
        {
            "offerId": "65322717CA02A12",
            "editionName": "VIPe 2 Adobe Presenter Licensed for Enterprise (Multi European Languages)"
        },
        {
            "offerId": "65324802CA03A12",
            "editionName": "VIP 3 Select Adobe Animate / Flash Professional Pro für Teams (Multi European Languages)"
        },
        {
            "offerId": "65325084CA04012",
            "editionName": "Promo VIP 4 Adobe Creative Cloud Pro für Teams (EU English)"
        },
        {
            "offerId": "65322700CA04A12",
            "editionName": "VIPe 4 Adobe Framemaker für Enterprise (EU English)"
        },
        {
            "offerId": "65324774CA03A12",
            "editionName": "VIPe 3 Adobe Lightroom Pro für Enterprise (Multi European Languages)"
        },
        {
            "offerId": "65324922CA03A12",
            "editionName": "VIPe 3 Adobe InDesign Pro für Enterprise (EU Englisch)"
        },
        {
            "offerId": "65322553CA04A12",
            "editionName": "VIP 4 Acrobat Sign Solutions for Enterprise, Azure (Multi EU Languages)"
        },
        {
            "offerId": "65324793CA01012",
            "editionName": "Promo VIP 1 Adobe Premiere Pro - Pro für Teams (Multi European Languages)"
        },
        {
            "offerId": "65324771CA03A12",
            "editionName": "VIP 3 Adobe Lightroom Classic Pro für Teams (Multi European Languages)"
        },
        {
            "offerId": "65305369CA01A12",
            "editionName": "VIP 1 Adobe Premiere Pro für Teams (EU English)"
        },
        {
            "offerId": "65322586CA03A12",
            "editionName": "VIP 3 Acrobat Sign Solutions for Business, Azure (Multi EU Languages)"
        },
        {
            "offerId": "65322493CA02A12",
            "editionName": "VIPe 2 Adobe FrameMaker Publishing Server für Enterprise (EU Englisch)"
        },
        {
            "offerId": "65322484CAT4A12",
            "editionName": "Tier 4 Acrobat Sign Solutions for Business, AWS (Multi EU Languages)"
        },
        {
            "offerId": "65324911CA02012",
            "editionName": "Promo VIP 2 Adobe Illustrator Pro for Teams (EU English)"
        },
        {
            "offerId": "65324911CA01012",
            "editionName": "Promo VIP 1 Adobe Illustrator Pro for Teams (EU English)"
        },
        {
            "offerId": "65324933CA02012",
            "editionName": "Promo VIP 2 Adobe After Effects Pro für Teams (Multi European Languages)"
        },
        {
            "offerId": "65323045CA02A12",
            "editionName": "VIPe 2 Adobe InCopy für Enterprise (Multi European Languages)"
        },
        {
            "offerId": "65305253CA04A12",
            "editionName": "VIP 4 Select Adobe Stock für Teams Small 10 Standard Assets (EU English)"
        },
        {
            "offerId": "65322565CA03A12",
            "editionName": "VIPe 3 Adobe Dimension für Enterprise (EU English)"
        },
        {
            "offerId": "65322700CA01A12",
            "editionName": "VIPe 1 Adobe Framemaker für Enterprise (EU English)"
        },
        {
            "offerId": "65324799CA01012",
            "editionName": "Promo VIP 1 Animate / Flash Professional Pro for teams (EU English)"
        },
        {
            "offerId": "65305243CA13A12",
            "editionName": "VIP 13 3 Year Commit Adobe Stock for teams (Large) (Multi EU Languages)"
        },
        {
            "offerId": "65322713CA04A12",
            "editionName": "VIPe 4 Adobe Presenter Licensed for Enterprise (EU Englisch)"
        },
        {
            "offerId": "65305183CA01A12",
            "editionName": "VIP 1 Adobe Illustrator für Teams (Multi European Languages)"
        },
        {
            "offerId": "65322607CA01A12",
            "editionName": "VIPe 1 Adobe Acrobat DC Standard für Enterprise (Multi European Languages)"
        },
        {
            "offerId": "65324897CA01012",
            "editionName": "Promo VIP 1 Audition Pro for Teams (EU English)"
        },
        {
            "offerId": "65322710CA02A12",
            "editionName": "VIPe 2 Adobe Captivate für Enterprise (EU English)"
        },
        {
            "offerId": "65305521CA03A12",
            "editionName": "VIP 3 Select Adobe Animate / Flash Professional für Teams (Multi European Languages)"
        },
        {
            "offerId": "65322488CA04A12",
            "editionName": "VIPe 4 Adobe FrameMaker Publishing Server für Enterprise (Multi European Languages)"
        },
        {
            "offerId": "65305243CA01A12",
            "editionName": "VIP 1 Adobe Stock für Teams Large 750 Standard Assets (Multi European Languages)"
        },
        {
            "offerId": "65324802CA01A12",
            "editionName": "VIP 1 Adobe Animate / Flash Professional Pro für Teams (Multi European Languages)"
        },
        {
            "offerId": "65324938CA12012",
            "editionName": "VIP 12 3Year Commit InDesign Pro for teams (Multi EU Languages)"
        },
        {
            "offerId": "65315888CA03A12",
            "editionName": "VIP 3 Select Adobe RoboHelp Office für Teams (EU English)"
        },
        {
            "offerId": "65322517CA01A12",
            "editionName": "VIPe 1 Adobe Fresco für Enterprise (EU English)"
        },
        {
            "offerId": "65322565CA01A12",
            "editionName": "VIPe 1 Adobe Dimension für Enterprise (EU English)"
        },
        {
            "offerId": "65322437CA03A12",
            "editionName": "VIPe 3 Adobe RoboHelp Server für Enterprise (EU English)"
        },
        {
            "offerId": "65305063CA03A12",
            "editionName": "VIP 3 Select Adobe Fresco für Teams (Multi European Languages)"
        },
        {
            "offerId": "65324849CA04012",
            "editionName": "Promo VIP 4 Dimension Pro für Teams (EU English)"
        },
        {
            "offerId": "65305282CA02A12",
            "editionName": "VIP 2 Select Adobe Stock für Teams Medium 40 Standard Assets (EU English)"
        },
        {
            "offerId": "65322644CA02A12",
            "editionName": "VIPe 2 Adobe Photoshop for Enterprise (EU Englisch)"
        },
        {
            "offerId": "65325070CA03A12",
            "editionName": "VIPe 3 Adobe Creative Cloud für Enterprise (Multi European Languages)"
        },
        {
            "offerId": "65324949CA02A12",
            "editionName": "VIPe 2 Adobe Premiere Pro - Pro für Enterprise (EU English)"
        },
        {
            "offerId": "65324903CA04012",
            "editionName": "Promo VIP 4 InCopy Pro for teams (EU English)"
        },
        {
            "offerId": "65324901CA03A12",
            "editionName": "VIP 3 Adobe Audition Pro für Teams (Multi European Languages)"
        },
        {
            "offerId": "65305248CA14A12",
            "editionName": "VIP 14 3 Year Commit Adobe Stock for teams (Small) (Multi EU Languages)"
        },
        {
            "offerId": "65322422CA13A12",
            "editionName": "VIP 13 Select 3 Year Commit Illustrator for Enterprise (Multi EU Languages)"
        },
        {
            "offerId": "65305248CA03A12",
            "editionName": "VIP 3 Select Adobe Stock für Teams Small 10 Standard Assets (Multi European Languages)"
        },
        {
            "offerId": "65305243CA02A12",
            "editionName": "VIP 2 Select Adobe Stock für Teams Large 750 Standard Assets (Multi European Languages)"
        },
        {
            "offerId": "65324897CA01A12",
            "editionName": "VIP 1 Adobe Audition Pro für Teams (EU English)"
        },
        {
            "offerId": "65305156CA14A12",
            "editionName": "VIP 14 Select 3 Year Commit Photoshop for teams (Multi EU Languages)"
        },
        {
            "offerId": "65322444CA04A12",
            "editionName": "VIPe 4 Adobe Creative Cloud für Enterprise (Multi European Languages)"
        },
        {
            "offerId": "65322717CA03A12",
            "editionName": "VIPe 3 Adobe Presenter Licensed for Enterprise (Multi European Languages)"
        },
        {
            "offerId": "65325064CA04A12",
            "editionName": "VIPe 4 Adobe Creative Cloud Pro für Enterprise (EU English)"
        },
        {
            "offerId": "65322443CA01A12",
            "editionName": "VIPe 1 Adobe Creative Cloud für Enterprise (Multi European Languages)"
        },
        {
            "offerId": "65304522CA04A12",
            "editionName": "VIP 4 Adobe Acrobat Pro für Teams (EU English)"
        },
        {
            "offerId": "65322648CA01A12",
            "editionName": "VIPe 1 Adobe Acrobat DC Pro für Enterprise (Multi European Languages)"
        },
        {
            "offerId": "65324849CA04A12",
            "editionName": "VIP 4 Adobe Dimension Pro für Teams (EU English)"
        },
        {
            "offerId": "65322558CA04A12",
            "editionName": "VIP 4 Acrobat Sign Solutions for Enterprise, AWS (Multi EU Languages)"
        },
        {
            "offerId": "65305308CA02A12",
            "editionName": "VIP 2 Select Adobe Lightroom Classic für Teams (EU English)"
        },
        {
            "offerId": "65322700CA03A12",
            "editionName": "VIPe 3 Adobe Framemaker für Enterprise (EU English)"
        },
        {
            "offerId": "65324942CA01A12",
            "editionName": "VIP 1 Adobe InDesign Pro für Teams (EU English)"
        },
        {
            "offerId": "65305596CA01A12",
            "editionName": "VIP 1 Adobe Acrobat Sign für SMB (EU English)"
        },
        {
            "offerId": "65324863CA01012",
            "editionName": "Promo VIP 1 Photoshop Pro for teams (Multi EU Languages)"
        },
        {
            "offerId": "65324841CA03A12",
            "editionName": "VIPe 3 Adobe Dreamweaver Pro für Enterprise (EU English)"
        },
        {
            "offerId": "65305370CA04A12",
            "editionName": "VIP 4 Select Adobe Premiere Pro für Teams (Multi European Languages)"
        },
        {
            "offerId": "65324935CA01A12",
            "editionName": "VIP 1 Adobe After Effects Pro für Teams (EU English)"
        },
        {
            "offerId": "65324820CA03A12",
            "editionName": "VIPe 3 Adobe After Effects Pro für Enterprise (Multi European Languages)"
        },
        {
            "offerId": "65305285CA04A12",
            "editionName": "VIP 4 Select Adobe Stock für Team Medium 40 Standard Assets (Multi European Languages)"
        },
        {
            "offerId": "65322656CA04A12",
            "editionName": "VIPe 4 Adobe InDesign for Enterprise (Multi European Languages)"
        },
        {
            "offerId": "65324935CA04A12",
            "editionName": "VIP 4 Adobe After Effects Pro für Teams (EU English)"
        },
        {
            "offerId": "65304764CA02A12",
            "editionName": "VIP 2 Select Adobe Technical Suite für Teams (EU English)"
        },
        {
            "offerId": "65305599CA04A12",
            "editionName": "VIP 4 Select Adobe Acrobat Sign für SMB (Multi European Languages)"
        },
        {
            "offerId": "65305343CA02A12",
            "editionName": "VIP 2 Select Adobe FrameMaker für Teams (Multi European Languages)"
        },
        {
            "offerId": "65324942CA04012",
            "editionName": "Promo VIP 4 InDesign Pro for teams (EU English)"
        },
        {
            "offerId": "65323054CA04A12",
            "editionName": "VIPe 4 Adobe Substance 3D Collection für Enterprise (Multi European Languages)"
        },
        {
            "offerId": "65304484CA02A12",
            "editionName": "VIP 2 Select Adobe Audition für Teams (EU English)"
        },
        {
            "offerId": "65324779CA03A12",
            "editionName": "VIP 3 Select Adobe XD Pro für Teams (Multi European Languages)"
        },
        {
            "offerId": "65322648CA14A12",
            "editionName": "VIP 14 Select 3 Year Commit Acrobat Pro for Enterprise (Multi EU Languages)"
        },
        {
            "offerId": "65304717CA13A12",
            "editionName": "VIP 13 Select 3 Year Commit InCopy for teams (Multi EU Languages)"
        },
        {
            "offerId": "65305065CA02A12",
            "editionName": "VIP 2 Select Adobe Fresco für Teams (EU English)"
        },
        {
            "offerId": "65324884CA02A12",
            "editionName": "VIP 2 Adobe Dreamweaver Pro für Teams (Multi European Languages)"
        },
        {
            "offerId": "65324916CA01A12",
            "editionName": "VIPe 1 Adobe Animate/Flash Professional Pro für Enterprise (EU English)"
        },
        {
            "offerId": "65305247CA01A12",
            "editionName": "VIP 1 Adobe Stock für Teams Large 750 Standard Assets (EU English)"
        },
        {
            "offerId": "65322696CA02A12",
            "editionName": "VIPe 2 Adobe RoboHelp Office für Enterprise (EU Englisch)"
        },
        {
            "offerId": "65324793CA04012",
            "editionName": "Promo VIP 4 Adobe Premiere Pro - Pro für Teams (Multi European Languages)"
        },
        {
            "offerId": "65304888CA04A12",
            "editionName": "VIP 4 Select Adobe Acrobat DC für Teams Standard (EU English)"
        },
        {
            "offerId": "65324893CA01A12",
            "editionName": "VIPe 1 Adobe Audition Pro für Enterprise (Multi European Languages)"
        },
        {
            "offerId": "65324833CA04A12",
            "editionName": "VIPe 4 Adobe XD Pro für Enterprise (Multi European Languages)"
        },
        {
            "offerId": "65305345CA01A12",
            "editionName": "VIP 1 Adobe FrameMaker für Teams (EU English)"
        },
        {
            "offerId": "65324863CA03A12",
            "editionName": "VIP 3  Adobe Photoshop Pro für Teams (Multi European Languages)"
        },
        {
            "offerId": "65322582CA02A12",
            "editionName": "VIPe 2 Adobe Animate/Flash Professional für Enterprise (Multi European Languages)"
        },
        {
            "offerId": "65323053CA02A12",
            "editionName": "VIPe 2 Adobe Substance 3D Collection for Enterprise (EU Englisch)"
        },
        {
            "offerId": "65324849CA01A12",
            "editionName": "VIP 1 Adobe Dimension Pro für Teams (EU English)"
        },
        {
            "offerId": "65324797CA02A12",
            "editionName": "VIP 2 Adobe Premiere Pro - Pro für Teams (EU Englisch)"
        },
        {
            "offerId": "65304764CA04A12",
            "editionName": "VIP 4 Select Adobe Technical Suite für Teams (EU English)"
        },
        {
            "offerId": "65305414CA04A12",
            "editionName": "VIP 4 Select Adobe InDesign für Teams (EU English)"
        },
        {
            "offerId": "65304717CA04A12",
            "editionName": "VIP 4 Select Adobe InCopy für Teams (Multi European Languages)"
        },
        {
            "offerId": "65304575CA02A12",
            "editionName": "VIP 2 Select Adobe Creative Cloud für Teams All Apps (EU English)"
        },
        {
            "offerId": "65317574CA01A12",
            "editionName": "VIP 1 Adobe Substance 3D Collection for Teams (Multi European Languages)"
        },
        {
            "offerId": "65324867CA02012",
            "editionName": "Promo VIP 2 Photoshop Pro for teams (EU English)"
        },
        {
            "offerId": "65322429CA04A12",
            "editionName": "VIPe 4 Adobe Audition für Enterprise (Multi European Languages)"
        },
        {
            "offerId": "65324828CA02A12",
            "editionName": "VIPe 2 Adobe Illustrator Pro für Enterprise (EU Englisch)"
        },
        {
            "offerId": "65322599CA01A12",
            "editionName": "Level 1: Annual plan (EU English) (EURO)"
        },
        {
            "offerId": "65322553CA01A12",
            "editionName": "VIP 1 Acrobat Sign Solutions for Enterprise, Azure (Multi EU Languages)"
        },
        {
            "offerId": "65322638CA01A12",
            "editionName": "VIPe 1 Adobe Photoshop für Enterprise (Multi European Languages)"
        },
        {
            "offerId": "65324867CA04012",
            "editionName": "Promo VIP 4 Photoshop Pro for teams (EU English)"
        },
        {
            "offerId": "65305243CA03A12",
            "editionName": "VIP 3 Select Adobe Stock für Teams Large 750 Standard Assets (Multi European Languages)"
        },
        {
            "offerId": "65322603CA03A12",
            "editionName": "VIPe 3 Acrobat Standard DC für Enterprise (EU English)"
        },
        {
            "offerId": "65322619CA01A12",
            "editionName": "VIPe 1 Adobe After Effects für Enterprise (Multi European Languages)"
        },
        {
            "offerId": "65304841CA02A12",
            "editionName": "VIP 2 Select Adobe Creative Cloud für Teams All Apps mit 10 Adobe Stock Standard Assets (Multi European Languages)"
        },
        {
            "offerId": "65324928CA14A12",
            "editionName": "VIP 14 Select 3 Year Commit InDesign Pro for Enterprise (Multi EU Languages)"
        },
        {
            "offerId": "65304575CA04A12",
            "editionName": "VIP 4 Select Adobe Creative Cloud für Teams All Apps (EU English)"
        },
        {
            "offerId": "65322505CA02A12",
            "editionName": "VIPe 2 Adobe Dreamweaver für Enterprise (EU English)"
        },
        {
            "offerId": "65304764CA03A12",
            "editionName": "VIP 3 Select Adobe Technical Suite für Teams (EU English)"
        },
        {
            "offerId": "65324785CA01012",
            "editionName": "Promo VIP 1 Lightroom Classic Pro for teams (EU English)"
        },
        {
            "offerId": "65305184CA02A12",
            "editionName": "VIP 2 Select Adobe Illustrator für Teams (EU English)"
        },
        {
            "offerId": "65323053CA03A12",
            "editionName": "VIPe 3 Adobe Substance 3D Collection for Enterprise (EU Englisch)"
        },
        {
            "offerId": "65305369CA02A12",
            "editionName": "VIP 2 Select Adobe Premiere Pro für Teams (EU English)"
        },
        {
            "offerId": "65304579CA02A12",
            "editionName": "VIP 2 Select Adobe Creative Cloud für Teams All Apps (Multi European Languages)"
        },
        {
            "offerId": "65322517CA02A12",
            "editionName": "VIPe 2 Adobe Fresco für Enterprise (EU English)"
        },
        {
            "offerId": "65323045CA12A12",
            "editionName": "VIP 12 Select 3 Year Commit InCopy for Enterprise (Multi EU Languages)"
        },
        {
            "offerId": "65322599CA03A12",
            "editionName": "Level 3: Annual plan (EU English) (EURO)"
        },
        {
            "offerId": "65322723CA04A12",
            "editionName": "VIPe 4 Adobe Technical Suite für Enterprise (EU Englisch)"
        },
        {
            "offerId": "65324928CA13A12",
            "editionName": "VIP 13 Select 3 Year Commit InDesign Pro for Enterprise (Multi EU Languages)"
        },
        {
            "offerId": "65324871CA03012",
            "editionName": "Promo VIP 3 Dimension Pro für Teams (Multi European Languages)"
        },
        {
            "offerId": "65324871CA02A12",
            "editionName": "VIP 2 Adobe Dimension Pro für Teams (Multi European Languages)"
        },
        {
            "offerId": "65324910CA04A12",
            "editionName": "VIP 4 Adobe Illustrator Pro für Teams (Multi European Languages))"
        },
        {
            "offerId": "65322595CA04A12",
            "editionName": "VIPe 4 Adobe XD für Enterprise (Multi European Languages)"
        },
        {
            "offerId": "65324928CA03A12",
            "editionName": "VIPe 3 Adobe InDesign Pro für Enterprise (Multi European Languages)"
        },
        {
            "offerId": "65325070CA14A12",
            "editionName": "VIP 14 Select 3 Year Commit Creative Cloud Pro for Enterprise All Apps (Multi EU Languages)"
        },
        {
            "offerId": "65324878CA02A12",
            "editionName": "VIPe 2 Adobe InCopy Pro für Enterprise (EU Englisch)"
        },
        {
            "offerId": "65315887CA02A12",
            "editionName": "VIP 2 Select Adobe RoboHelp Office für Teams (Multi European Languages)"
        },
        {
            "offerId": "65304841CA03A12",
            "editionName": "VIP 3 Select Adobe Creative Cloud für Teams All Apps mit 10 Adobe Stock Standard Assets (Multi European Languages)"
        },
        {
            "offerId": "65322725CA03A12",
            "editionName": "VIPe 3 Adobe Technical Suite für Enterprise (Multi European Languages)"
        },
        {
            "offerId": "65324916CA02A12",
            "editionName": "VIPe 2 Adobe Animate/Flash Professional Pro für Enterprise (EU English)"
        },
        {
            "offerId": "65324797CA04012",
            "editionName": "Promo VIP 4 Adobe Premiere Pro - Pro für Teams (EU Englisch)"
        },
        {
            "offerId": "65304717CA03A12",
            "editionName": "VIP 3 Select Adobe InCopy für Teams (Multi European Languages)"
        },
        {
            "offerId": "65304717CA02A12",
            "editionName": "VIP 2 Select Adobe InCopy für Teams (Multi European Languages)"
        },
        {
            "offerId": "65305410CA14A12",
            "editionName": "VIP 14 Select 3 Year Commit InDesign for teams (Multi EU Languages)"
        },
        {
            "offerId": "65324828CA01A12",
            "editionName": "VIPe 1 Adobe Illustrator Pro für Enterprise (EU Englisch)"
        },
        {
            "offerId": "65324910CA02012",
            "editionName": "Promo VIP 2 Adobe Illustrator Pro for Teams (Multi EU Languages)"
        },
        {
            "offerId": "65324903CA01A12",
            "editionName": "VIP 1 Adobe InCopy Pro für Teams (EU English)"
        },
        {
            "offerId": "65323045CA01A12",
            "editionName": "VIPe 1 Adobe InCopy für Enterprise (Multi European Languages)"
        },
        {
            "offerId": "65324933CA01012",
            "editionName": "Promo VIP 1 Adobe After Effects Pro für Teams (Multi European Languages)"
        },
        {
            "offerId": "65323054CA13A12",
            "editionName": "VIP 13 Select 3 Year Commit Substance 3D Collection for Enterprise (Multi EU Languages)"
        },
        {
            "offerId": "65322582CA04A12",
            "editionName": "VIPe 4 Adobe Animate/Flash Professional für Enterprise (Multi European Languages)"
        },
        {
            "offerId": "65322599CA02A12",
            "editionName": "Level 2: Annual plan (EU English) (EURO)"
        },
        {
            "offerId": "65323045CA04A12",
            "editionName": "VIPe 4 Adobe InCopy für Enterprise (Multi European Languages)"
        },
        {
            "offerId": "65304886CA13A12",
            "editionName": "VIP 13 Select 3 Year Commit Acrobat Standard DC for teams (Multi EU Languages)"
        },
        {
            "offerId": "65322471CA03A12",
            "editionName": "VIPe 3 Adobe Premiere Pro für Enterprise (Multi European Languages)"
        },
        {
            "offerId": "65324878CA03A12",
            "editionName": "VIPe 3 Adobe InCopy Pro für Enterprise (EU Englisch)"
        },
        {
            "offerId": "65317571CA01A12",
            "editionName": "VIP 1 Adobe Substance 3D Collection for Teams (EU English)"
        },
        {
            "offerId": "65305313CA02A12",
            "editionName": "VIP 2 Select Adobe Lightroom Classic für Teams (Multi European Languages)"
        },
        {
            "offerId": "65324841CA01A12",
            "editionName": "VIPe 1 Adobe Dreamweaver Pro für Enterprise (EU English)"
        },
        {
            "offerId": "65322517CA03A12",
            "editionName": "VIPe 3 Adobe Fresco für Enterprise (EU English)"
        },
        {
            "offerId": "65322644CA01A12",
            "editionName": "VIPe 1 Adobe Photoshop für Enterprise (EU Englisch)"
        },
        {
            "offerId": "65324774CA01A12",
            "editionName": "VIPe 1 Adobe Lightroom Pro für Enterprise (Multi European Languages)"
        },
        {
            "offerId": "65323045CA13A12",
            "editionName": "VIP 13 Select 3 Year Commit InCopy for Enterprise (Multi EU Languages)"
        },
        {
            "offerId": "65324945CA04A12",
            "editionName": "VIPe 4 Adobe Premiere Pro - Pro für Enterprise (Multi European Languages)"
        },
        {
            "offerId": "65325064CA02A12",
            "editionName": "VIPe 2 Adobe Creative Cloud Pro für Enterprise (EU English)"
        },
        {
            "offerId": "65322534CAT1A12",
            "editionName": "Tier 1 Acrobat Sign Solutions for Enterprise, Azure (Multi EU Languages)"
        },
        {
            "offerId": "65322582CA01A12",
            "editionName": "VIPe 1 Adobe Animate/Flash Professional für Enterprise (Multi European Languages)"
        },
        {
            "offerId": "65324915CA04A12",
            "editionName": "VIPe 4 Adobe Animate/Flash Professional Pro für Enterprise (Multi European Languages)"
        },
        {
            "offerId": "65305313CA01A12",
            "editionName": "VIP 1 Adobe Lightroom Classic für Teams (Multi European Languages)"
        },
        {
            "offerId": "65324871CA01A12",
            "editionName": "VIP 1 Adobe Dimension Pro für Teams (Multi European Languages)"
        },
        {
            "offerId": "65305521CA04A12",
            "editionName": "VIP 4 Select Adobe Animate / Flash Professional für Teams (Multi European Languages)"
        },
        {
            "offerId": "65324774CA02A12",
            "editionName": "VIPe 2 Adobe Lightroom Pro für Enterprise (Multi European Languages)"
        },
        {
            "offerId": "65324901CA01012",
            "editionName": "Promo VIP 1 Audition Pro for Teams (Multi EU Languages)"
        },
        {
            "offerId": "65325078CA01012",
            "editionName": "Promo VIP 1 Adobe Creative Cloud Pro für Teams All Apps (Multi European Languages)"
        },
        {
            "offerId": "65305121CA01A12",
            "editionName": "VIP 1 Adobe XD für Teams (EU English)"
        },
        {
            "offerId": "65324942CA01012",
            "editionName": "Promo VIP 1 InDesign Pro for teams (EU English)"
        },
        {
            "offerId": "65305414CA01A12",
            "editionName": "VIP 1 Adobe InDesign für Teams (EU English)"
        },
        {
            "offerId": "65304599CA03A12",
            "editionName": "VIP 3 Select Adobe After Effects für Teams (Multi European Languages)"
        },
        {
            "offerId": "65324902CA04A12",
            "editionName": "VIP 4 Adobe InCopy Pro für Teams (Multi European Languages)"
        },
        {
            "offerId": "65305285CA03A12",
            "editionName": "VIP 3 Select Adobe Stock für Team Medium 40 Standard Assets (Multi European Languages)"
        },
        {
            "offerId": "65315887CA03A12",
            "editionName": "VIP 3 Select Adobe RoboHelp Office für Teams (Multi European Languages)"
        },
        {
            "offerId": "65324910CA03012",
            "editionName": "Promo VIP 3 Adobe Illustrator Pro for Teams (Multi EU Languages)"
        },
        {
            "offerId": "65305183CA04A12",
            "editionName": "VIP 4 Select Adobe Illustrator für Teams (Multi European Languages))"
        },
        {
            "offerId": "65324799CA04012",
            "editionName": "Promo VIP 4 Animate / Flash Professional Pro for teams (EU English)"
        },
        {
            "offerId": "65324863CA02012",
            "editionName": "Promo VIP 2 Photoshop Pro for teams (Multi EU Languages)"
        },
        {
            "offerId": "65322649CA04A12",
            "editionName": "VIPe 4 Acrobat Pro DC für Enterprise (EU English)"
        },
        {
            "offerId": "65324771CA04A12",
            "editionName": "VIP 4 Adobe Lightroom Classic Pro für Teams (Multi European Languages)"
        },
        {
            "offerId": "65304841CA12A12",
            "editionName": "VIP 12 Select 3 Year Commit Creative Cloud for teams All Apps with Adobe Stock (Multi EU Languages)"
        },
        {
            "offerId": "65324793CA01A12",
            "editionName": "VIP 1 Adobe Premiere Pro - Pro für Teams (Multi European Languages)"
        },
        {
            "offerId": "65322519CA01A12",
            "editionName": "VIPe 1 Adobe Fresco für Enterprise (Multi European Languages)"
        },
        {
            "offerId": "65315888CA02A12",
            "editionName": "VIP 2 Select Adobe RoboHelp Office für Teams (EU English)"
        },
        {
            "offerId": "65304886CA01A12",
            "editionName": "VIP 1 Adobe Acrobat DC für Teams Standard (Multi European Languages)"
        },
        {
            "offerId": "65322504CA01A12",
            "editionName": "VIPe 1 Adobe Dreamweaver für Enterprise (Multi European Languages)"
        },
        {
            "offerId": "65324863CA04A12",
            "editionName": "VIP 4 Select Adobe Photoshop Pro für Teams (Multi European Languages)"
        },
        {
            "offerId": "65322656CA01A12",
            "editionName": "VIPe 1 Adobe InDesign for Enterprise (Multi European Languages)"
        },
        {
            "offerId": "65304888CA03A12",
            "editionName": "VIP 3 Select Adobe Acrobat DC für Teams Standard (EU English)"
        },
        {
            "offerId": "65304579CA04A12",
            "editionName": "VIP 4 Select Adobe Creative Cloud für Teams All Apps (Multi European Languages)"
        },
        {
            "offerId": "65305599CA12A12",
            "editionName": "VIP 12 Select 3 Year Commit Acrobat Sign for SMB (Multi EU Languages)"
        },
        {
            "offerId": "65323054CA01A12",
            "editionName": "VIPe 1 Adobe Substance 3D Collection für Enterprise (Multi European Languages)"
        },
        {
            "offerId": "65324799CA03012",
            "editionName": "Promo VIP 3 Animate / Flash Professional Pro for teams (EU English)"
        },
        {
            "offerId": "65324779CA01012",
            "editionName": "Promo VIP 1 Adobe XD Pro für Teams (Multi European Languages)"
        },
        {
            "offerId": "65322417CA03A12",
            "editionName": "VIPe 3 Adobe Illustrator für Enterprise (EU Englisch)"
        },
        {
            "offerId": "65304716CA01A12",
            "editionName": "VIP 1 Adobe InCopy für Teams (EU English)"
        },
        {
            "offerId": "65322699CA03A12",
            "editionName": "VIPe 3 Adobe Framemaker für Enterprise (Multi European Languages)"
        },
        {
            "offerId": "65322630CA02A12",
            "editionName": "VIPe 2 Adobe Lightroom Pro für Enterprise (EU Englisch)"
        },
        {
            "offerId": "65324802CA02A12",
            "editionName": "VIP 2 Select Adobe Animate / Flash Professional Pro für Teams (Multi European Languages)"
        },
        {
            "offerId": "65325084CA04A12",
            "editionName": "VIP 4 Adobe Creative Cloud Pro für Teams (EU English)"
        },
        {
            "offerId": "65324802CA01012",
            "editionName": "Promo VIP 1 Select Animate / Flash Professional Pro for teams (Multi EU Languages)"
        },
        {
            "offerId": "65322619CA03A12",
            "editionName": "VIPe 3 Adobe After Effects für Enterprise (Multi European Languages)"
        },
        {
            "offerId": "65325070CA04A12",
            "editionName": "VIPe 4 Adobe Creative Cloud Pro für Enterprise (Multi European Languages)"
        },
        {
            "offerId": "65322588CA01A12",
            "editionName": "VIP 1 Acrobat Sign Solutions for Business, AWS (Multi EU Languages)"
        },
        {
            "offerId": "65305156CA03A12",
            "editionName": "VIP 3 Select Adobe Photoshop für Teams (Multi European Languages)"
        },
        {
            "offerId": "65304754CA01A12",
            "editionName": "VIP 1 Adobe Captivate für Teams (Multi European Languages)"
        },
        {
            "offerId": "65324884CA01012",
            "editionName": "VIP 1 Adobe Dreamweaver Pro für Teams First-Year-Promo (Multi EU Languages)"
        },
        {
            "offerId": "65322588CA03A12",
            "editionName": "VIP 3 Acrobat Sign Solutions for Business, AWS (Multi EU Languages)"
        },
        {
            "offerId": "65324871CA04A12",
            "editionName": "VIP 4 Adobe Dimension für Teams (Multi European Languages)"
        },
        {
            "offerId": "65324771CA01A12",
            "editionName": "VIP 1 Adobe Lightroom Classic Pro für Teams (Multi European Languages)"
        },
        {
            "offerId": "65305183CA02A12",
            "editionName": "VIP 2 Select Adobe Illustrator für Teams (Multi European Languages)"
        },
        {
            "offerId": "65305313CA03A12",
            "editionName": "VIP 3 Select Adobe Lightroom Classic für Teams (Multi European Languages)"
        },
        {
            "offerId": "65305065CA03A12",
            "editionName": "VIP 3 Select Adobe Fresco für Teams (EU English)"
        },
        {
            "offerId": "65322586CA01A12",
            "editionName": "VIP 1 Acrobat Sign Solutions for Business, Azure (Multi EU Languages)"
        },
        {
            "offerId": "65323053CA01A12",
            "editionName": "VIPe 1 Adobe Substance 3D Collection for Enterprise (EU Englisch)"
        },
        {
            "offerId": "65324893CA04A12",
            "editionName": "VIPe 4 Adobe Audition Pro für Enterprise (Multi European Languages)"
        },
        {
            "offerId": "65325084CA03A12",
            "editionName": "VIP 3 Adobe Creative Cloud Pro für Teams (EU English)"
        },
        {
            "offerId": "65324945CA02A12",
            "editionName": "VIPe 2 Adobe Premiere Pro - Pro für Enterprise (Multi European Languages)"
        },
        {
            "offerId": "65324942CA03A12",
            "editionName": "VIP 3 Adobe InDesign Pro für Teams (EU English)"
        },
        {
            "offerId": "65305343CA04A12",
            "editionName": "VIP 4 Select Adobe FrameMaker für Teams (Multi European Languages)"
        },
        {
            "offerId": "65324826CA04A12",
            "editionName": "VIPe 4 Adobe Illustrator Pro für Enterprise (Multi European Languages)"
        },
        {
            "offerId": "65324902CA02012",
            "editionName": "Promo VIP 2 InCopy Pro for teams (Multi EU Languages)"
        },
        {
            "offerId": "65304918CA03A12",
            "editionName": "VIP 3 Select Adobe Dreamweaver für Teams (Multi European Languages)"
        },
        {
            "offerId": "65304599CA02A12",
            "editionName": "VIP 2 Select Adobe After Effects für Teams (Multi European Languages)"
        },
        {
            "offerId": "65305450CA02A12",
            "editionName": "VIP 2 Select Adobe Dimension für Teams (Multi European Languages)"
        },
        {
            "offerId": "65324922CA04A12",
            "editionName": "VIPe 4 Adobe InDesign Pro für Enterprise (EU Englisch)"
        },
        {
            "offerId": "65322437CA01A12",
            "editionName": "VIPe 1 Adobe RoboHelp Server für Enterprise (EU English)"
        },
        {
            "offerId": "65322710CA01A12",
            "editionName": "VIPe 1 Adobe Captivate für Enterprise (EU English)"
        },
        {
            "offerId": "65304521CA03A12",
            "editionName": "VIP 3 Adobe Acrobat Pro für Teams (Multi European Language)"
        },
        {
            "offerId": "65322623CA01A12",
            "editionName": "VIPe 1 Adobe After Effects für Enterprise (EU English)"
        },
        {
            "offerId": "65324911CA04A12",
            "editionName": "VIP 4 Adobe Illustrator Pro für Teams (EU English)"
        },
        {
            "offerId": "65324782CA02A12",
            "editionName": "VIP 2 Select Adobe XD Pro für Teams (EU English)"
        },
        {
            "offerId": "65322588CA02A12",
            "editionName": "VIP 2 Acrobat Sign Solutions for Business, AWS (Multi EU Languages)"
        },
        {
            "offerId": "65322429CA01A12",
            "editionName": "VIPe 1 Adobe Audition für Enterprise (Multi European Languages)"
        },
        {
            "offerId": "65322699CA04A12",
            "editionName": "VIPe 4 Adobe Framemaker für Enterprise (Multi European Languages)"
        },
        {
            "offerId": "65325078CA01A12",
            "editionName": "VIP 1 Adobe Creative Cloud Pro für Teams All Apps (Multi European Languages)"
        },
        {
            "offerId": "65305118CA12A12",
            "editionName": "VIP 12 Select 3 Year Commit Adobe XD for teams (Multi EU Languages)"
        },
        {
            "offerId": "65304741CA03A12",
            "editionName": "VIP 3 Select Adobe Captivate für Teams (EU English)"
        },
        {
            "offerId": "65324849CA02A12",
            "editionName": "VIP 2 Adobe Dimension Pro für Teams (EU English)"
        },
        {
            "offerId": "65325084CA01A12",
            "editionName": "VIP 1 Adobe Creative Cloud Pro für Teams All Apps (EU English)"
        },
        {
            "offerId": "65324771CA01012",
            "editionName": "Promo VIP 1 Lightroom Classic Pro for teams (Multi EU Languages)"
        },
        {
            "offerId": "65324826CA03A12",
            "editionName": "VIPe 3 Adobe Illustrator Pro für Enterprise (Multi European Languages)"
        },
        {
            "offerId": "65324799CA02A12",
            "editionName": "VIP 2 Select Adobe Animate / Flash Professional Pro für Teams (EU English)"
        },
        {
            "offerId": "65324782CA04012",
            "editionName": "Promo VIP 4 Adobe XD Pro für Teams (EU English)"
        },
        {
            "offerId": "65322653CA01A12",
            "editionName": "VIPe 1 Adobe InDesign for Enterprise (EU Englisch)"
        },
        {
            "offerId": "65322444CA02A12",
            "editionName": "VIPe 2 Adobe Creative Cloud für Enterprise (Multi European Languages)"
        },
        {
            "offerId": "65317571CA04A12",
            "editionName": "VIP 4 Select Adobe Substance 3D Collection for Teams (EU English)"
        },
        {
            "offerId": "65324857CA02A12",
            "editionName": "VIPe 2 Adobe Photoshop Pro für Enterprise (Multi European Languages)"
        },
        {
            "offerId": "65322422CA01A12",
            "editionName": "VIPe 1 Adobe Illustrator für Enterprise (Multi European Languages)"
        },
        {
            "offerId": "65324859CA03A12",
            "editionName": "VIPe 3 Adobe Photoshop Pro für Enterprise (EU Englisch)"
        },
        {
            "offerId": "65322649CA02A12",
            "editionName": "VIPe 2 Acrobat Pro DC für Enterprise (EU English)"
        },
        {
            "offerId": "65305121CA04A12",
            "editionName": "VIP 4 Select Adobe XD für Teams (EU English)"
        },
        {
            "offerId": "65304919CA02A12",
            "editionName": "VIP 2 Select Adobe Dreamweaver für Teams (EU English)"
        },
        {
            "offerId": "65322427CA01A12",
            "editionName": "VIPe 1 Adobe Audition für Enterprise (EU English)"
        },
        {
            "offerId": "65322504CA03A12",
            "editionName": "VIPe 3 Adobe Dreamweaver für Enterprise (Multi European Languages)"
        },
        {
            "offerId": "65324793CA03A12",
            "editionName": "VIP 3 Select Adobe Premiere Pro - Pro für Teams (Multi European Languages)"
        },
        {
            "offerId": "65322603CA04A12",
            "editionName": "VIPe 4 Acrobat DC Standard für Enterprise (EU English)"
        },
        {
            "offerId": "65322710CA03A12",
            "editionName": "VIPe 3 Adobe Captivate für Enterprise (EU English)"
        },
        {
            "offerId": "65324771CA02A12",
            "editionName": "VIP 2 Select Adobe Lightroom Classic Pro für Teams (Multi European Languages)"
        },
        {
            "offerId": "65305300CA01A12",
            "editionName": "VIP 1 Adobe RoboHelp Office für Teams (EU English)"
        },
        {
            "offerId": "65322427CA02A12",
            "editionName": "VIPe 2 Adobe Audition für Enterprise (EU English)"
        },
        {
            "offerId": "65322648CA03A12",
            "editionName": "VIPe 3 Adobe Acrobat DC Pro für Enterprise (Multi European Languages)"
        },
        {
            "offerId": "65323043CA04A12",
            "editionName": "VIPe 4 Adobe InCopy für Enterprise (EU Englisch)"
        },
        {
            "offerId": "65305285CA01A12",
            "editionName": "VIP 1 Adobe Stock für Teams Medium 40 Standard Assets (Multi European Languages)"
        },
        {
            "offerId": "65324771CA02012",
            "editionName": "Promo VIP 2 Lightroom Classic Pro for teams (Multi EU Languages)"
        },
        {
            "offerId": "65323054CA14A12",
            "editionName": "VIP 14 Select 3 Year Commit Substance 3D Collection for Enterprise (Multi EU Languages)"
        },
        {
            "offerId": "65322623CA04A12",
            "editionName": "VIPe 4 Adobe After Effects für Enterprise (EU English)"
        },
        {
            "offerId": "65304717CA14A12",
            "editionName": "VIP 14 Select 3 Year Commit InCopy for teams (Multi EU Languages)"
        },
        {
            "offerId": "65323043CA02A12",
            "editionName": "VIPe 2 Adobe InCopy für Enterprise (EU Englisch)"
        },
        {
            "offerId": "65324776CA04A12",
            "editionName": "VIPe 4 Adobe Lightroom Pro für Enterprise (EU Englisch)"
        },
        {
            "offerId": "65322581CA02A12",
            "editionName": "VIPe 2 Adobe Animate/Flash Professional für Enterprise (EU English)"
        },
        {
            "offerId": "65324863CA02A12",
            "editionName": "VIP 2 Adobe Photoshop Pro für Teams (Multi European Languages)"
        },
        {
            "offerId": "65324901CA04012",
            "editionName": "Promo VIP 4 Audition Pro for Teams (Multi EU Languages)"
        },
        {
            "offerId": "65305158CA04A12",
            "editionName": "VIP 4 Select Adobe Photoshop für Teams (EU English)"
        },
        {
            "offerId": "65305450CA01A12",
            "editionName": "VIP 1 Adobe Dimension für Teams (Multi European Languages)"
        },
        {
            "offerId": "65325078CA02A12",
            "editionName": "VIP 2 Adobe Creative Cloud Pro für Teams (Multi European Languages)"
        },
        {
            "offerId": "65305118CA01A12",
            "editionName": "VIP 1 Adobe XD für Teams (Multi European Languages)"
        },
        {
            "offerId": "65322472CA04A12",
            "editionName": "VIPe 4 Adobe Premiere Pro für Enterprise (EU English)"
        },
        {
            "offerId": "65304919CA03A12",
            "editionName": "VIP 3 Select Adobe Dreamweaver für Teams (EU English)"
        },
        {
            "offerId": "65324859CA04A12",
            "editionName": "VIPe 4 Adobe Photoshop Pro für Enterprise (EU Englisch)"
        },
        {
            "offerId": "65324793CA02012",
            "editionName": "Promo VIP 2 Adobe Premiere Pro - Pro für Teams (Multi European Languages)"
        },
        {
            "offerId": "65304886CA04A12",
            "editionName": "VIP 4 Select Adobe Acrobat DC für Teams Standard (Multi European Languages)"
        },
        {
            "offerId": "65305599CA01A12",
            "editionName": "VIP 1 Adobe Acrobat Sign für SMB (Multi European Languages)"
        },
        {
            "offerId": "65322417CA04A12",
            "editionName": "VIPe 4 Adobe Illustrator für Enterprise (EU Englisch)"
        },
        {
            "offerId": "65323043CA03A12",
            "editionName": "VIPe 3 Adobe InCopy für Enterprise (EU Englisch)"
        },
        {
            "offerId": "65305521CA02A12",
            "editionName": "VIP 2 Select Adobe Animate / Flash Professional für Teams (Multi European Languages)"
        },
        {
            "offerId": "65323054CA02A12",
            "editionName": "VIPe 2 Adobe Substance 3D Collection für Enterprise (Multi European Languages)"
        },
        {
            "offerId": "65304521CA14A12",
            "editionName": "VIP 14 Select 3 Year Commit Acrobat Pro for teams (Multi EU Languages)"
        },
        {
            "offerId": "65324782CA03012",
            "editionName": "Promo VIP 3 Adobe XD Pro für Teams (EU English)"
        },
        {
            "offerId": "65304888CA02A12",
            "editionName": "VIP 2 Select Adobe Acrobat DC für Teams Standard (EU English)"
        },
        {
            "offerId": "65305118CA02A12",
            "editionName": "VIP 2 Select Adobe XD für Teams (Multi European Languages)"
        },
        {
            "offerId": "65324820CA04A12",
            "editionName": "VIPe 4 Adobe After Effects Pro für Enterprise (Multi European Languages)"
        },
        {
            "offerId": "65324823CA04A12",
            "editionName": "VIPe 4 Adobe After Effects Pro für Enterprise (EU English)"
        },
        {
            "offerId": "65305308CA03A12",
            "editionName": "VIP 3 Select Adobe Lightroom Classic für Teams (EU English)"
        },
        {
            "offerId": "65322563CA03A12",
            "editionName": "VIPe 3 Adobe Dimension für Enterprise (Multi European Languages)"
        },
        {
            "offerId": "65324782CA01A12",
            "editionName": "VIP 1 Adobe XD Pro für Teams (EU English)"
        },
        {
            "offerId": "65324863CA04012",
            "editionName": "Promo VIP 4 Photoshop Pro for teams (Multi EU Languages)"
        },
        {
            "offerId": "65324875CA02A12",
            "editionName": "VIPe 2 Adobe InCopy Pro für Enterprise (Multi European Languages)"
        },
        {
            "offerId": "65324928CA04A12",
            "editionName": "VIPe 4 Adobe InDesign Pro für Enterprise (Multi European Languages)"
        },
        {
            "offerId": "65304575CA03A12",
            "editionName": "VIP 3 Select Adobe Creative Cloud für Teams All Apps (EU English)"
        },
        {
            "offerId": "65322434CAT3A12",
            "editionName": "Tier 3 Acrobat Sign Solutions for Enterprise, AWS (Multi EU Languages)"
        },
        {
            "offerId": "65324857CA14A12",
            "editionName": "VIP 14 Select 3 Year Commit Photoshop Pro for Enterprise (Multi EU Languages)"
        },
        {
            "offerId": "65305121CA03A12",
            "editionName": "VIP 3 Select Adobe XD für Teams (EU English)"
        },
        {
            "offerId": "65322708CA02A12",
            "editionName": "VIPe 2 Adobe Captivate für Enterprise (Multi European Languages)"
        },
        {
            "offerId": "65305156CA04A12",
            "editionName": "VIP 4 Select Adobe Photoshop für Teams (Multi European Languages)"
        },
        {
            "offerId": "65324915CA02A12",
            "editionName": "VIPe 2 Adobe Animate/Flash Professional Pro für Enterprise (Multi European Languages)"
        },
        {
            "offerId": "65324797CA01A12",
            "editionName": "VIP 1 Adobe Premiere Pro - Pro für Teams (EU Englisch)"
        },
        {
            "offerId": "65304607CA04A12",
            "editionName": "VIP 4 Select Adobe After Effects für Teams (EU English)"
        },
        {
            "offerId": "65325078CA04012",
            "editionName": "Promo VIP 4 Adobe Creative Cloud Pro für Teams All Apps (Multi European Languages)"
        },
        {
            "offerId": "65305248CA04A12",
            "editionName": "VIP 4 Select Stock für Teams Small 10 Standard Assets (Multi European Languages)"
        },
        {
            "offerId": "65324799CA04A12",
            "editionName": "VIP 4 Select Adobe Animate / Flash Professional Pro für Teams (EU English)"
        },
        {
            "offerId": "65322429CA02A12",
            "editionName": "VIPe 2 Adobe Audition für Enterprise (Multi European Languages)"
        },
        {
            "offerId": "65305158CA01A12",
            "editionName": "VIP 1 Adobe Photoshop für Teams (EU English)"
        },
        {
            "offerId": "65324776CA01A12",
            "editionName": "VIPe 1 Adobe Lightroom Pro für Enterprise (EU Englisch)"
        },
        {
            "offerId": "65323045CA03A12",
            "editionName": "VIPe 3 Adobe InCopy für Enterprise (Multi European Languages)"
        },
        {
            "offerId": "65324833CA01A12",
            "editionName": "VIPe 1 Adobe XD Pro für Enterprise (Multi European Languages)"
        },
        {
            "offerId": "65322563CA01A12",
            "editionName": "VIPe 1 Adobe Dimension für Enterprise (Multi European Languages)"
        },
        {
            "offerId": "65322558CA02A12",
            "editionName": "VIP 2 Acrobat Sign Solutions for Enterprise, AWS (Multi EU Languages)"
        },
        {
            "offerId": "65324871CA03A12",
            "editionName": "VIP 3 Adobe Dimension für Teams (Multi European Languages)"
        },
        {
            "offerId": "65324911CA03A12",
            "editionName": "VIP 3 Adobe Illustrator Pro für Teams (EU English)"
        },
        {
            "offerId": "65322471CA01A12",
            "editionName": "VIPe 1 Adobe Premiere Pro für Enterprise (Multi European Languages)"
        },
        {
            "offerId": "65304575CA01A12",
            "editionName": "VIP 1 Adobe Creative Cloud für Teams All Apps (EU English)"
        },
        {
            "offerId": "65322440CA03A12",
            "editionName": "VIPe 3 Adobe RoboHelp Server für Enterprise (Multi European Languages)"
        },
        {
            "offerId": "65322440CA04A12",
            "editionName": "VIPe 4 Adobe RoboHelp Server für Enterprise(Multi European Languages)"
        },
        {
            "offerId": "65322656CA03A12",
            "editionName": "VIPe 3 Adobe InDesign for Enterprise (Multi European Languages)"
        },
        {
            "offerId": "65324901CA01A12",
            "editionName": "VIP 1 Adobe Audition Pro für Teams (Multi European Languages)"
        },
        {
            "offerId": "65322422CA03A12",
            "editionName": "VIPe 3 Adobe Illustrator für Enterprise (Multi European Languages)"
        },
        {
            "offerId": "65322698CA01A12",
            "editionName": "VIPe 1 Adobe RoboHelp Office für Enterprise (Multi European Languages)"
        },
        {
            "offerId": "65322537CAT2A12",
            "editionName": "Tier 2 Acrobat Sign Solutions for Business, Azure (Multi EU Languages)"
        },
        {
            "offerId": "65322553CA03A12",
            "editionName": "VIP 3 Acrobat Sign Solutions for Enterprise, Azure (Multi EU Languages)"
        },
        {
            "offerId": "65324785CA04012",
            "editionName": "Promo VIP 4 Lightroom Classic Pro for teams (EU English)"
        },
        {
            "offerId": "65305118CA04A12",
            "editionName": "VIP 4 Select Adobe XD für Teams (Multi European Languages)"
        },
        {
            "offerId": "65322586CA02A12",
            "editionName": "VIP 2 Acrobat Sign Solutions for Business, Azure (Multi EU Languages)"
        },
        {
            "offerId": "65324938CA02A12",
            "editionName": "VIP 2 Adobe InDesign Pro für Teams (Multi European Languages)"
        },
        {
            "offerId": "65305121CA02A12",
            "editionName": "VIP 2 Select Adobe XD für Teams (EU English)"
        },
        {
            "offerId": "65325078CA03A12",
            "editionName": "VIP 3 Adobe Creative Cloud Pro für Teams (Multi European Languages)"
        },
        {
            "offerId": "65324901CA04A12",
            "editionName": "VIP 4 Adobe Audition Pro für Teams (Multi European Languages)"
        },
        {
            "offerId": "65324849CA01012",
            "editionName": "Promo VIP 1 Dimension Pro für Teams (EU English)"
        },
        {
            "offerId": "65324889CA04A12",
            "editionName": "VIPe 4 Adobe Audition Pro für Enterprise (EU English)"
        },
        {
            "offerId": "65322443CA02A12",
            "editionName": "VIPe 2 Adobe Creative Cloud für Enterprise (EU English)"
        },
        {
            "offerId": "65304521CA12A12",
            "editionName": "VIP 12 Select 3 Year Commit Acrobat Pro for teams (Multi EU Languages)"
        },
        {
            "offerId": "65324902CA03A12",
            "editionName": "VIP 3 Adobe InCopy Pro für Teams (Multi European Languages)"
        },
        {
            "offerId": "65304918CA01A12",
            "editionName": "VIP 1 Adobe Dreamweaver für Teams (Multi European Languages)"
        },
        {
            "offerId": "65323478CA04012",
            "editionName": "VIPe 4 Adobe Express für Enterprise (Multi European Languages)"
        },
        {
            "offerId": "65304579CA03A12",
            "editionName": "VIP 3 Select Adobe Creative Cloud für Teams All Apps (Multi European Languages)"
        },
        {
            "offerId": "65322700CA02A12",
            "editionName": "VIPe 2 Adobe Framemaker für Enterprise (EU English)"
        },
        {
            "offerId": "65322630CA03A12",
            "editionName": "VIPe 3 Adobe Lightroom Classic für Enterprise (EU Englisch)"
        },
        {
            "offerId": "65322696CA03A12",
            "editionName": "VIPe 3 Adobe RoboHelp Office für Enterprise (EU Englisch)"
        },
        {
            "offerId": "65324835CA02A12",
            "editionName": "VIPe 2 Adobe XD Pro für Enterprise (EU English)"
        },
        {
            "offerId": "65305410CA03A12",
            "editionName": "VIP 3 Select Adobe InDesign für Teams (Multi European Languages)"
        },
        {
            "offerId": "65324884CA02012",
            "editionName": "Promo VIP 2 Adobe Dreamweaver Pro für Teams (Multi European Languages)"
        },
        {
            "offerId": "65322534CAT4A12",
            "editionName": "Tier 4 Acrobat Sign Solutions for Enterprise, Azure (Multi EU Languages)"
        },
        {
            "offerId": "65322644CA04A12",
            "editionName": "VIPe 4 Adobe Photoshop for Enterprise (EU Englisch)"
        },
        {
            "offerId": "65322565CA02A12",
            "editionName": "VIPe 2 Adobe Dimension für Enterprise (EU English)"
        },
        {
            "offerId": "65324935CA04012",
            "editionName": "Promo VIP 4 Adobe After Effects Pro für Teams (EU English"
        },
        {
            "offerId": "65322648CA02A12",
            "editionName": "VIPe 2 Adobe Acrobat DC Pro für Enterprise (Multi European Languages)"
        },
        {
            "offerId": "65324889CA03A12",
            "editionName": "VIPe 3 Adobe Audition Pro für Enterprise (EU English)"
        },
        {
            "offerId": "65322638CA12A12",
            "editionName": "VIP 12 Select 3 Year Commit Photoshop for Enterprise (Multi EU Languages)"
        },
        {
            "offerId": "65324790CA01A12",
            "editionName": "VIPe 1 Adobe Dimension Pro für Enterprise (EU English)"
        },
        {
            "offerId": "65324785CA01A12",
            "editionName": "VIP 1 Adobe Lightroom Classic Pro für Teams (EU English)"
        },
        {
            "offerId": "65305449CA03A12",
            "editionName": "VIP 3 Select Adobe Dimension für Teams (EU English)"
        },
        {
            "offerId": "65322504CA04A12",
            "editionName": "VIPe 4 Adobe Dreamweaver für Enterprise (Multi European Languages)"
        },
        {
            "offerId": "65317574CA02A12",
            "editionName": "VIP 2 Select Adobe Substance 3D Collection for Teams (Multi European Languages)"
        },
        {
            "offerId": "65304754CA03A12",
            "editionName": "VIP 3 Select Adobe Captivate für Teams (Multi European Languages)"
        },
        {
            "offerId": "65304579CA01A12",
            "editionName": "VIP 1 Adobe Creative Cloud für Teams All Apps (Multi European Languages)"
        },
        {
            "offerId": "65324779CA04A12",
            "editionName": "VIP 4 Select Adobe XD Pro für Teams (Multi European Languages)"
        },
        {
            "offerId": "65324903CA01012",
            "editionName": "Promo VIP 1 InCopy Pro for teams (EU English)"
        },
        {
            "offerId": "65324799CA01A12",
            "editionName": "VIP 1 Adobe Animate / Flash Professional Pro für Teams (EU English)"
        },
        {
            "offerId": "65324867CA01A12",
            "editionName": "VIP 1 Adobe Photoshop Pro für Teams (EU English)"
        },
        {
            "offerId": "65325070CA12A12",
            "editionName": "VIP 12 Select 3 Year Commit Creative Cloud Pro for Enterprise All Apps (Multi EU Languages)"
        },
        {
            "offerId": "65322653CA04A12",
            "editionName": "VIPe 4 Adobe InDesign for Enterprise (EU Englisch)"
        },
        {
            "offerId": "65304888CA01A12",
            "editionName": "VIP 1 Adobe Acrobat DC für Teams Standard (EU English)"
        },
        {
            "offerId": "65324797CA01012",
            "editionName": "Promo VIP 1 Premiere Pro - Pro für Teams (EU English)"
        },
        {
            "offerId": "65305410CA04A12",
            "editionName": "VIP 4 Select Adobe InDesign für Teams (Multi European Languages)"
        },
        {
            "offerId": "65324903CA04A12",
            "editionName": "VIP 4 Adobe InCopy Pro für Teams (EU English)"
        },
        {
            "offerId": "65304484CA04A12",
            "editionName": "VIP 4 Select Adobe Audition für Teams (EU English)"
        },
        {
            "offerId": "65322607CA03A12",
            "editionName": "VIPe 3 Adobe Acrobat DC Standard für Enterprise (Multi European Languages)"
        },
        {
            "offerId": "65322638CA04A12",
            "editionName": "VIPe 4 Adobe Photoshop für Enterprise (Multi European Languages)"
        },
        {
            "offerId": "65305596CA02A12",
            "editionName": "VIP 2 Select Adobe Acrobat Sign für SMB (EU English)"
        },
        {
            "offerId": "65305507CA02A12",
            "editionName": "VIP 2 Select Adobe Animate / Flash Professional für Teams (EU English)"
        },
        {
            "offerId": "65324897CA04012",
            "editionName": "Promo VIP 4 Audition Pro for Teams (EU English)"
        },
        {
            "offerId": "65324797CA04A12",
            "editionName": "VIP 4 Adobe Premiere Pro - Pro für Teams (EU Englisch)"
        },
        {
            "offerId": "65304754CA14A12",
            "editionName": "VIP 14 3 Year Commit Captivate for teams (Multi EU Languages)"
        },
        {
            "offerId": "65305282CA01A12",
            "editionName": "VIP 1 Adobe Stock für Teams Medium 40 Standard Assets (EU English)"
        },
        {
            "offerId": "65324935CA01012",
            "editionName": "Promo VIP 1 Adobe After Effects Pro für Teams (EU English)"
        },
        {
            "offerId": "65305243CA04A12",
            "editionName": "VIP 4 Select Adobe Stock für Teams Large 750 Standard Assets (Multi European Languages)"
        },
        {
            "offerId": "65322443CA04A12",
            "editionName": "VIPe 4 Adobe Creative Cloud für Enterprise (EU English)"
        },
        {
            "offerId": "65322638CA02A12",
            "editionName": "VIPe 2 Adobe Photoshop für Enterprise (Multi European Languages)"
        },
        {
            "offerId": "65322696CA01A12",
            "editionName": "VIPe 1 Adobe RoboHelp Office für Enterprise (EU Englisch)"
        },
        {
            "offerId": "65305599CA03A12",
            "editionName": "VIP 3 Select Adobe Acrobat Sign für SMB (Multi European Languages)"
        },
        {
            "offerId": "65322493CA04A12",
            "editionName": "VIPe 4 Adobe FrameMaker Publishing Server für Enterprise (EU Englisch)"
        },
        {
            "offerId": "65324875CA01A12",
            "editionName": "VIPe 1 Adobe InCopy Pro für Enterprise (Multi European Languages)"
        },
        {
            "offerId": "65322725CA01A12",
            "editionName": "VIPe 1 Adobe Technical Suite für Enterprise (Multi European Languages)"
        },
        {
            "offerId": "65305596CA04A12",
            "editionName": "VIP 4 Select Adobe Acrobat Sign für SMB (EU English)"
        },
        {
            "offerId": "65324790CA02A12",
            "editionName": "VIPe 2 Adobe Dimension Pro für Enterprise (EU English)"
        },
        {
            "offerId": "65322599CA04A12",
            "editionName": "Level 4: Annual plan (EU English) (EURO)"
        },
        {
            "offerId": "65324826CA01A12",
            "editionName": "VIPe 1 Adobe Illustrator Pro für Enterprise (Multi European Languages)"
        },
        {
            "offerId": "65324857CA01A12",
            "editionName": "VIPe 1 Adobe Photoshop Pro für Enterprise (Multi European Languages)"
        },
        {
            "offerId": "65304716CA04A12",
            "editionName": "VIP 4 Select Adobe InCopy für Teams (EU English)"
        },
        {
            "offerId": "65305285CA13A12",
            "editionName": "VIP 13 3 Year Commit Adobe Stock for teams (Medium) (Multi EU Languages)"
        },
        {
            "offerId": "65324839CA04A12",
            "editionName": "VIPe 4 Adobe Dreamweaver Pro für Enterprise (Multi European Languages)"
        },
        {
            "offerId": "65304521CA01A12",
            "editionName": "VIP 1 Adobe Acrobat Pro für Teams (Multi European Language)"
        },
        {
            "offerId": "65324938CA01A12",
            "editionName": "VIP 1 Adobe InDesign Pro für Teams (Multi European Languages)"
        },
        {
            "offerId": "65324793CA04A12",
            "editionName": "VIP 4 Select Adobe Premiere Pro - Pro für Teams (Multi European Languages)"
        },
        {
            "offerId": "65324774CA04A12",
            "editionName": "VIPe 4 Adobe Lightroom Pro für Enterprise (Multi European Languages)"
        },
        {
            "offerId": "65304741CA02A12",
            "editionName": "VIP 2 Select Adobe Captivate für Teams (EU English)"
        },
        {
            "offerId": "65305063CA01A12",
            "editionName": "VIP 1 Adobe Fresco für Teams (Multi European Languages)"
        },
        {
            "offerId": "65322638CA14A12",
            "editionName": "VIP 14 Select 3 Year Commit Photoshop for Enterprise (Multi EU Languages)"
        },
        {
            "offerId": "65325084CA03012",
            "editionName": "Promo VIP 3 Adobe Creative Cloud Pro für Teams (EU English)"
        },
        {
            "offerId": "65322563CA04A12",
            "editionName": "VIPe 4 Adobe Dimension für Enterprise (Multi EU Languages)"
        },
        {
            "offerId": "65322537CAT3A12",
            "editionName": "Tier 3 Acrobat Sign Solutions for Business, Azure (Multi EU Languages)"
        },
        {
            "offerId": "65304763CA03A12",
            "editionName": "VIP 3 Select Adobe Technical Suite für Teams (Multi European Languages)"
        },
        {
            "offerId": "65322628CA03A12",
            "editionName": "VIPe 3 Adobe Lightroom Classic für Enterprise (Multi European Languages)"
        },
        {
            "offerId": "65324793CA03012",
            "editionName": "Promo VIP 3 Adobe Premiere Pro - Pro für Teams (Multi European Languages)"
        },
        {
            "offerId": "65324922CA02A12",
            "editionName": "VIPe 2 Adobe InDesign Pro für Enterprise (EU Englisch)"
        },
        {
            "offerId": "65315887CA04A12",
            "editionName": "VIP 4 Select Adobe RoboHelp Office für Teams (Multi European Languages)"
        },
        {
            "offerId": "65324871CA02012",
            "editionName": "Promo VIP 2 Dimension Pro für Teams (Multi European Languages)"
        },
        {
            "offerId": "65325078CA04A12",
            "editionName": "VIP 4 Adobe Creative Cloud Pro für Teams (Multi European Languages)"
        },
        {
            "offerId": "65304484CA03A12",
            "editionName": "VIP 3 Select Adobe Audition für Teams (EU English)"
        },
        {
            "offerId": "65322581CA04A12",
            "editionName": "VIPe 4 Adobe Animate/Flash Professional für Enterprise (EU English)"
        },
        {
            "offerId": "65324902CA03012",
            "editionName": "Promo VIP 3 InCopy Pro for teams (Multi EU Languages)"
        },
        {
            "offerId": "65324793CA02A12",
            "editionName": "VIP 2 Select Adobe Premiere Pro - Pro für Teams (Multi European Languages)"
        },
        {
            "offerId": "65322488CA01A12",
            "editionName": "VIPe 1 Adobe FrameMaker Publishing Server für Enterprise (Multi European Languages)"
        },
        {
            "offerId": "65305247CA03A12",
            "editionName": "VIP 3 Select Adobe Stock für Teams Large 750 Standard Assets (EU English)"
        },
        {
            "offerId": "65305183CA13A12",
            "editionName": "VIP 13 Select 3 Year Commit Illustrator for teams (Multi EU Languages)"
        },
        {
            "offerId": "65324857CA12A12",
            "editionName": "VIP 12 Select 3 Year Commit Photoshop Pro for Enterprise (Multi EU Languages)"
        },
        {
            "offerId": "65324799CA03A12",
            "editionName": "VIP 3 Select Adobe Animate / Flash Professional Pro für Teams (EU English)"
        },
        {
            "offerId": "65305599CA02A12",
            "editionName": "VIP 2 Select Adobe Acrobat Sign für SMB (Multi European Languages)"
        },
        {
            "offerId": "65324949CA01A12",
            "editionName": "VIPe 1 Adobe Premiere Pro - Pro für Enterprise (EU English)"
        },
        {
            "offerId": "65324902CA01A12",
            "editionName": "VIP 1 Adobe InCopy Pro für Teams (Multi European Languages)"
        },
        {
            "offerId": "65324922CA01A12",
            "editionName": "VIPe 1 Adobe InDesign Pro für Enterprise (EU Englisch)"
        },
        {
            "offerId": "65325084CA02A12",
            "editionName": "VIP 2 Adobe Creative Cloud Pro für Teams (EU English)"
        },
        {
            "offerId": "65305156CA12A12",
            "editionName": "VIP 12 Select 3 Year Commit Photoshop for teams (Multi EU Languages)"
        },
        {
            "offerId": "65324928CA02A12",
            "editionName": "VIPe 2 Adobe InDesign Pro für Enterprise (Multi European Languages)"
        },
        {
            "offerId": "65305282CA04A12",
            "editionName": "VIP 4 Select Adobe Stock für Teams Medium 40 Standard Assets (EU English)"
        },
        {
            "offerId": "65322713CA01A12",
            "editionName": "VIPe 1 Adobe Presenter Licensed for Enterprise (EU Englisch)"
        },
        {
            "offerId": "65322434CAT1A12",
            "editionName": "Tier 1 Acrobat Sign Solutions for Enterprise, AWS (Multi EU Languages)"
        },
        {
            "offerId": "65322519CA02A12",
            "editionName": "VIPe 2 Adobe Fresco für Enterprise (Multi European Languages)"
        },
        {
            "offerId": "65324839CA03A12",
            "editionName": "VIPe 3 Adobe Dreamweaver Pro für Enterprise (Multi European Languages)"
        },
        {
            "offerId": "65322422CA14A12",
            "editionName": "VIP 14 Select 3 Year Commit Illustrator for Enterprise (Multi EU Languages)"
        },
        {
            "offerId": "65305410CA01A12",
            "editionName": "VIP 1 Adobe InDesign für Teams (Multi European Languages)"
        },
        {
            "offerId": "65317571CA02A12",
            "editionName": "VIP 2 Select Adobe Substance 3D Collection for Teams (EU English)"
        },
        {
            "offerId": "65322638CA03A12",
            "editionName": "VIPe 3 Adobe Photoshop für Enterprise (Multi European Languages)"
        },
        {
            "offerId": "65324782CA02012",
            "editionName": "Promo VIP 2 Adobe XD Pro für Teams (EU English)"
        },
        {
            "offerId": "65324863CA03012",
            "editionName": "Promo VIP 3 Photoshop Pro for teams (Multi EU Languages)"
        },
        {
            "offerId": "65322553CA02A12",
            "editionName": "VIP 2 Acrobat Sign Solutions for Enterprise, Azure (Multi EU Languages)"
        },
        {
            "offerId": "65324952CA02A12",
            "editionName": "VIPe 2 Adobe Dimension Pro für Enterprise (Multi European Languages)"
        },
        {
            "offerId": "65324826CA02A12",
            "editionName": "VIPe 2 Adobe Illustrator Pro für Enterprise (Multi European Languages)"
        },
        {
            "offerId": "65322505CA03A12",
            "editionName": "VIPe 3 Adobe Dreamweaver für Enterprise (EU English)"
        },
        {
            "offerId": "65324916CA03A12",
            "editionName": "VIPe 3 Adobe Animate/Flash Professional Pro für Enterprise (EU English)"
        },
        {
            "offerId": "65305410CA02A12",
            "editionName": "VIP 2 Select Adobe InDesign für Teams (Multi European Languages)"
        },
        {
            "offerId": "65324910CA03A12",
            "editionName": "VIP 3 Adobe Illustrator Pro für Teams (Multi European Languages)"
        },
        {
            "offerId": "65324835CA01A12",
            "editionName": "VIPe 1 Adobe XD Pro für Enterprise (EU English) (EURO)"
        },
        {
            "offerId": "65324903CA02012",
            "editionName": "Promo VIP 2 InCopy Pro for teams (EU English)"
        },
        {
            "offerId": "65305184CA03A12",
            "editionName": "VIP 3 Select Adobe Illustrator für Teams (EU English)"
        },
        {
            "offerId": "65324835CA03A12",
            "editionName": "VIPe 3 Adobe XD Pro für Enterprise (EU English)"
        },
        {
            "offerId": "65324867CA02A12",
            "editionName": "VIP 2 Adobe Photoshop Pro für Teams (EU English)"
        },
        {
            "offerId": "65322586CA04A12",
            "editionName": "VIP 4 Acrobat Sign Solutions for Business, Azure (Multi EU Languages)"
        },
        {
            "offerId": "65324938CA04A12",
            "editionName": "VIP 4 Adobe InDesign Pro für Teams (Multi European Languages)"
        },
        {
            "offerId": "65324857CA04A12",
            "editionName": "VIPe 4 Adobe Photoshop für Enterprise (Multi European Languages)"
        },
        {
            "offerId": "65322699CA02A12",
            "editionName": "VIPe 2 Adobe Framemaker für Enterprise (Multi European Languages)"
        },
        {
            "offerId": "65322429CA03A12",
            "editionName": "VIPe 3 Adobe Audition für Enterprise (Multi European Languages)"
        },
        {
            "offerId": "65324915CA01A12",
            "editionName": "VIPe 1 Adobe Animate/Flash Professional Pro für Enterprise (Multi European Languages)"
        },
        {
            "offerId": "65304521CA02A12",
            "editionName": "VIP 2 Adobe Acrobat Pro für Teams (Multi European Language)"
        },
        {
            "offerId": "65325078CA03012",
            "editionName": "Promo VIP 3 Adobe Creative Cloud Pro für Teams All Apps (Multi European Languages)"
        },
        {
            "offerId": "65324867CA03012",
            "editionName": "Promo VIP 3 Photoshop Pro for teams (EU English)"
        },
        {
            "offerId": "65322595CA01A12",
            "editionName": "VIPe 1 Adobe XD für Enterprise (Multi European Languages)"
        },
        {
            "offerId": "65304482CA01A12",
            "editionName": "VIP 1 Adobe Audition für Teams (Multi European Languages)"
        },
        {
            "offerId": "65324802CA02012",
            "editionName": "Promo VIP 2 Select Animate / Flash Professional Pro for teams (Multi EU Languages)"
        },
        {
            "offerId": "65324928CA12A12",
            "editionName": "VIP 12 Select 3 Year Commit InDesign Pro for Enterprise (Multi EU Languages)"
        },
        {
            "offerId": "65305343CA01A12",
            "editionName": "VIP 1 Adobe FrameMaker für Teams (Multi European Languages)"
        },
        {
            "offerId": "65323054CA12A12",
            "editionName": "VIP 12 Select 3 Year Commit Substance 3D Collection for Enterprise (Multi EU Languages)"
        },
        {
            "offerId": "65324875CA03A12",
            "editionName": "VIPe 3 Adobe InCopy Pro für Enterprise (Multi European Languages)"
        },
        {
            "offerId": "65322558CA03A12",
            "editionName": "VIP 3 Acrobat Sign Solutions for Enterprise, AWS (Multi EU Languages)"
        },
        {
            "offerId": "65322519CA03A12",
            "editionName": "VIPe 3 Adobe Fresco für Enterprise (Multi European Languages)"
        },
        {
            "offerId": "65304717CA12A12",
            "editionName": "VIP 12 Select 3 Year Commit InCopy for teams (Multi EU Languages)"
        },
        {
            "offerId": "65324782CA01012",
            "editionName": "Promo VIP 1 Adobe XD Pro für Teams (EU English)"
        },
        {
            "offerId": "65322493CA03A12",
            "editionName": "VIPe 3 Adobe FrameMaker Publishing Server für Enterprise (EU Englisch)"
        },
        {
            "offerId": "65324826CA13A12",
            "editionName": "VIP 13 Select 3 Year Commit Illustrator Pro for Enterprise (Multi EU Languages)"
        },
        {
            "offerId": "65304763CA04A12",
            "editionName": "VIP 4 Select Adobe Technical Suite für Teams (Multi European Languages)"
        },
        {
            "offerId": "65305369CA04A12",
            "editionName": "VIP 4 Select Adobe Premiere Pro für Teams (EU English)"
        },
        {
            "offerId": "65324785CA02012",
            "editionName": "Promo VIP 2 Lightroom Classic Pro for teams (EU English)"
        },
        {
            "offerId": "65324942CA03012",
            "editionName": "Promo VIP 3 InDesign Pro for teams (EU English)"
        },
        {
            "offerId": "65305450CA03A12",
            "editionName": "VIP 3 Select Adobe Dimension für Teams (Multi European Languages)"
        },
        {
            "offerId": "65305158CA02A12",
            "editionName": "VIP 2 Select Adobe Photoshop für Teams (EU English)"
        },
        {
            "offerId": "65325084CA02012",
            "editionName": "Promo VIP 2 Adobe Creative Cloud Pro für Teams (EU English)"
        },
        {
            "offerId": "65324835CA04A12",
            "editionName": "VIPe 4 Adobe XD Pro für Enterprise (EU Englisch)"
        },
        {
            "offerId": "65324779CA04012",
            "editionName": "Promo VIP 4 Adobe XD Pro für Teams (Multi European Languages)"
        },
        {
            "offerId": "65304886CA03A12",
            "editionName": "VIP 3 Select Adobe Acrobat DC für Teams Standard (Multi European Languages)"
        },
        {
            "offerId": "65322713CA03A12",
            "editionName": "VIPe 3 Adobe Presenter Licensed for Enterprise (EU Englisch)"
        },
        {
            "offerId": "65304763CA02A12",
            "editionName": "VIP 2 Select Adobe Technical Suite für Teams (Multi European Languages)"
        },
        {
            "offerId": "65322493CA01A12",
            "editionName": "VIPe 1 Adobe FrameMaker Publishing Server für Enterprise (EU Englisch)"
        },
        {
            "offerId": "65324902CA01012",
            "editionName": "Promo VIP 1 InCopy Pro for teams (Multi EU Languages)"
        },
        {
            "offerId": "65322588CA04A12",
            "editionName": "VIP 4 Acrobat Sign Solutions for Business, AWS (Multi EU Languages)"
        },
        {
            "offerId": "65304579CA13A12",
            "editionName": "VIP 13 Select 3 Year Commit Creative Cloud for teams All Apps (Multi EU Languages)"
        },
        {
            "offerId": "65322504CA02A12",
            "editionName": "VIPe 2 Adobe Dreamweaver für Enterprise (Multi European Languages)"
        },
        {
            "offerId": "65324952CA01A12",
            "editionName": "VIPe 1 Adobe Dimension Pro für Enterprise (Multi European Languages)"
        },
        {
            "offerId": "65325078CA02012",
            "editionName": "Promo VIP 2 Adobe Creative Cloud Pro für Teams All Apps (Multi European Languages)"
        },
        {
            "offerId": "65324849CA03012",
            "editionName": "Promo VIP 3 Dimension Pro für Teams (EU English)"
        },
        {
            "offerId": "65305313CA04A12",
            "editionName": "VIP 4 Select Adobe Lightroom Classic für Teams (Multi European Languages)"
        },
        {
            "offerId": "65324799CA02012",
            "editionName": "Promo VIP 2 Animate / Flash Professional Pro for teams (EU English)"
        },
        {
            "offerId": "65305449CA02A12",
            "editionName": "VIP 2 Select Adobe Dimension für Teams (EU English)"
        },
        {
            "offerId": "65322488CA03A12",
            "editionName": "VIPe 3 Adobe FrameMaker Publishing Server für Enterprise (Multi European Languages)"
        },
        {
            "offerId": "65305370CA02A12",
            "editionName": "VIP 2 Select Adobe Premiere Pro für Teams (Multi European Languages)"
        },
        {
            "offerId": "65305243CA12A12",
            "editionName": "VIP 12 3 Year Commit Adobe Stock for teams (Large) (Multi EU Languages)"
        },
        {
            "offerId": "65317574CA03A12",
            "editionName": "VIP 3 Select Adobe Substance 3D Collection for Teams (Multi European Languages)"
        },
        {
            "offerId": "65305184CA01A12",
            "editionName": "VIP 1 Adobe Illustrator für Teams (EU English)"
        },
        {
            "offerId": "65324949CA04A12",
            "editionName": "VIPe 4 Adobe Premiere Pro - Pro für Enterprise (EU English)"
        },
        {
            "offerId": "65324897CA04A12",
            "editionName": "VIP 4 Adobe Audition Pro für Teams (EU English)"
        },
        {
            "offerId": "65324935CA02012",
            "editionName": "Promo VIP 2 Adobe After Effects Pro für Teams (EU English"
        },
        {
            "offerId": "65324820CA01A12",
            "editionName": "VIPe 1 Adobe After Effects Pro für Enterprise (Multi European Languages)"
        },
        {
            "offerId": "65322443CA14A12",
            "editionName": "VIP 14 Select 3 Year Commit Creative Cloud for Enterprise All Apps (Multi EU Languages)"
        },
        {
            "offerId": "65322708CA04A12",
            "editionName": "VIPe 4 Adobe Captivate für Enterprise (Multi European Languages)"
        },
        {
            "offerId": "65305599CA14A12",
            "editionName": "VIP 14 Select 3 Year Commit Acrobat Sign for SMB (Multi EU Languages)"
        },
        {
            "offerId": "65325070CA02A12",
            "editionName": "VIPe 2 Adobe Creative Cloud Pro für Enterprise (Multi European Languages)"
        },
        {
            "offerId": "65304886CA14A12",
            "editionName": "VIP 14 Select 3 Year Commit Acrobat Standard DC for teams (Multi EU Languages)"
        },
        {
            "offerId": "65324901CA03012",
            "editionName": "Promo VIP 3 Audition Pro for Teams (Multi EU Languages)"
        },
        {
            "offerId": "65324938CA04012",
            "editionName": "Promo VIP 4 InDesign Pro for teams (Multi EU Languages)"
        },
        {
            "offerId": "65324859CA02A12",
            "editionName": "VIPe 2 Adobe Photoshop Pro für Enterprise (EU Englisch)"
        },
        {
            "offerId": "65324797CA03A12",
            "editionName": "VIP 3 Adobe Premiere Pro - Pro für Teams (EU Englisch)"
        },
        {
            "offerId": "65305247CA02A12",
            "editionName": "VIP 2 Select Adobe Stock für Teams Large 750 Standard Assets (EU English)"
        },
        {
            "offerId": "65324911CA02A12",
            "editionName": "VIP 2 Adobe Illustrator Pro für Teams (EU English)"
        },
        {
            "offerId": "65324935CA03A12",
            "editionName": "VIP 3 Adobe After Effects Pro für Teams (EU English)"
        },
        {
            "offerId": "65322723CA03A12",
            "editionName": "VIPe 3 Adobe Technical Suite für Enterprise (EU Englisch)"
        },
        {
            "offerId": "65324902CA04012",
            "editionName": "Promo VIP 4 InCopy Pro for teams (Multi EU Languages)"
        },
        {
            "offerId": "65322505CA01A12",
            "editionName": "VIPe 1 Adobe Dreamweaver für Enterprise (EU English)"
        },
        {
            "offerId": "65324911CA03012",
            "editionName": "Promo VIP 3 Adobe Illustrator Pro for Teams (EU English)"
        },
        {
            "offerId": "65322656CA12A12",
            "editionName": "VIP 12 Select 3 Year Commit InDesign for Enterprise (Multi EU Languages)"
        },
        {
            "offerId": "65305308CA04A12",
            "editionName": "VIP 4 Select Adobe Lightroom Classic für Teams (EU English)"
        },
        {
            "offerId": "65324849CA02012",
            "editionName": "Promo VIP 2 Dimension Pro für Teams (EU English)"
        },
        {
            "offerId": "65322628CA04A12",
            "editionName": "VIPe 4 Adobe Lightroom Classic für Enterprise (Multi European Languages)"
        },
        {
            "offerId": "65317574CA14A12",
            "editionName": "VIP 14 Select 3 Year Commit Substance 3D Collection for Teams (Multi EU Languages)"
        },
        {
            "offerId": "65304482CA03A12",
            "editionName": "VIP 3 Select Adobe Audition für Teams (Multi European Languages)"
        },
        {
            "offerId": "65305450CA04A12",
            "editionName": "VIP 4 Select Adobe Dimension für Teams (Multi European Languages)"
        },
        {
            "offerId": "65324933CA01A12",
            "editionName": "VIP 1 Adobe After Effects Pro für Teams (Multi European Languages)"
        },
        {
            "offerId": "65322534CAT2A12",
            "editionName": "Tier 2 Acrobat Sign Solutions for Enterprise, Azure (Multi EU Languages)"
        },
        {
            "offerId": "65305184CA04A12",
            "editionName": "VIP 4 Select Adobe Illustrator für Teams (EU English)"
        },
        {
            "offerId": "65305118CA13A12",
            "editionName": "VIP 13 Select 3 Year Commit Adobe XD for teams (Multi EU Languages)"
        },
        {
            "offerId": "65322437CA04A12",
            "editionName": "VIPe 4 Adobe RoboHelp Server für Enterprise (EU English)"
        },
        {
            "offerId": "65324782CA03A12",
            "editionName": "VIP 3 Select Adobe XD Pro für Teams (EU English)"
        },
        {
            "offerId": "65304482CA02A12",
            "editionName": "VIP 2 Select Adobe Audition für Teams (Multi European Languages)"
        },
        {
            "offerId": "65305248CA02A12",
            "editionName": "VIP 2 Select Adobe Stock für Teams Small 10 Standard Assets (Multi European Languages)"
        },
        {
            "offerId": "65322427CA03A12",
            "editionName": "VIPe 3 Adobe Audition für Enterprise (EU English)"
        },
        {
            "offerId": "65324776CA03A12",
            "editionName": "VIPe 3 Adobe Lightroom Pro für Enterprise (EU Englisch)"
        },
        {
            "offerId": "65322607CA14A12",
            "editionName": "VIP 14 Select 3 Year Commit Acrobat Standard DC for Enterprise (Multi EU Languages)"
        },
        {
            "offerId": "65325064CA03A12",
            "editionName": "VIPe 3 Adobe Creative Cloud Pro für Enterprise (EU English)"
        },
        {
            "offerId": "65305308CA01A12",
            "editionName": "VIP 1 Adobe Lightroom Classic für Teams (EU English)"
        },
        {
            "offerId": "65305507CA01A12",
            "editionName": "VIP 1 Adobe Animate / Flash Professional für Teams (EU English)"
        },
        {
            "offerId": "65322723CA02A12",
            "editionName": "VIPe 2 Adobe Technical Suite für Enterprise (EU Englisch)"
        },
        {
            "offerId": "65324823CA02A12",
            "editionName": "VIPe 2 Adobe After Effects Pro für Enterprise (EU English)"
        },
        {
            "offerId": "65324820CA02A12",
            "editionName": "VIPe 2 Adobe After Effects Pro für Enterprise (Multi European Languages)"
        },
        {
            "offerId": "65322537CAT1A12",
            "editionName": "Tier 1 Acrobat Sign Solutions for Business, Azure (Multi EU Languages)"
        },
        {
            "offerId": "65305449CA04A12",
            "editionName": "VIP 4 Select Adobe Dimension für Teams (EU English)"
        },
        {
            "offerId": "65322649CA01A12",
            "editionName": "VIPe 1 Acrobat Pro DC für Enterprise (EU English)"
        },
        {
            "offerId": "65322630CA01A12",
            "editionName": "VIPe 1 Adobe Lightroom Classic for Enterprise (EU Englisch)"
        },
        {
            "offerId": "65324878CA04A12",
            "editionName": "VIPe 4 Adobe InCopy Pro für Enterprise (EU Englisch)"
        },
        {
            "offerId": "65322472CA01A12",
            "editionName": "VIPe 1 Adobe Premiere Pro für Enterprise (EU English)"
        },
        {
            "offerId": "65322443CA03A12",
            "editionName": "VIPe 3 Adobe Creative Cloud für Enterprise (EU English)"
        },
        {
            "offerId": "65305370CA03A12",
            "editionName": "VIP 3 Select Adobe Premiere Pro für Teams (Multi European Languages)"
        },
        {
            "offerId": "65322422CA02A12",
            "editionName": "VIPe 2 Adobe Illustrator für Enterprise (Multi European Languages)"
        },
        {
            "offerId": "65324871CA01012",
            "editionName": "Promo VIP 1 Dimension Pro für Teams (Multi European Languages)"
        },
        {
            "offerId": "65322484CAT2A12",
            "editionName": "Tier 2 Acrobat Sign Solutions for Business, AWS (Multi EU Languages)"
        },
        {
            "offerId": "65324935CA03012",
            "editionName": "Promo VIP 3 Adobe After Effects Pro für Teams (EU English"
        },
        {
            "offerId": "65324903CA03012",
            "editionName": "Promo VIP 3 InCopy Pro for teams (EU English)"
        },
        {
            "offerId": "65315888CA04A12",
            "editionName": "VIP 4 Select Adobe RoboHelp Office für Teams (EU English)"
        },
        {
            "offerId": "65324910CA04012",
            "editionName": "Promo VIP 4 Adobe Illustrator Pro for Teams (Multi EU Languages)"
        },
        {
            "offerId": "65322563CA02A12",
            "editionName": "VIPe 2 Adobe Dimension für Enterprise (Multi European Languages)"
        },
        {
            "offerId": "65322630CA04A12",
            "editionName": "VIPe 4 Adobe Lightroom Classic für Enterprise (EU Englisch)"
        },
        {
            "offerId": "65304716CA03A12",
            "editionName": "VIP 3 Select Adobe InCopy für Teams (EU English)"
        },
        {
            "offerId": "65322648CA04A12",
            "editionName": "VIPe 4 Adobe Acrobat DC Pro für Enterprise (Multi European Languages)"
        },
        {
            "offerId": "65304521CA04A12",
            "editionName": "VIP 4 Adobe Acrobat Pro für Teams (Multi European Language)"
        },
        {
            "offerId": "65322653CA03A12",
            "editionName": "VIPe 3 Adobe InDesign for Enterprise (EU Englisch)"
        },
        {
            "offerId": "65322581CA01A12",
            "editionName": "VIPe 1 Adobe Animate/Flash Professional für Enterprise (EU English)"
        },
        {
            "offerId": "65324797CA02012",
            "editionName": "Promo VIP 2 Adobe Premiere Pro - Pro für Teams (EU Englisch)"
        },
        {
            "offerId": "65304579CA14A12",
            "editionName": "VIP 14 Select 3 Year Commit Creative Cloud for teams All Apps (Multi EU Languages)"
        },
        {
            "offerId": "65305507CA04A12",
            "editionName": "VIP 4 Select Adobe Animate / Flash Professional für Teams (EU English)"
        },
        {
            "offerId": "65322595CA02A12",
            "editionName": "VIPe 2 Adobe XD für Enterprise (Multi European Languages)"
        },
        {
            "offerId": "65305507CA03A12",
            "editionName": "VIP 3 Select Adobe Animate / Flash Professional für Teams (EU English)"
        },
        {
            "offerId": "65304763CA01A12",
            "editionName": "VIP 1 Adobe Technical Suite für Teams (Multi European Languages)"
        },
        {
            "offerId": "65322504CA14A12",
            "editionName": "VIPe 14 3 Year Commit Adobe Dreamweaver for Enterprise (Multi EU Languages)"
        },
        {
            "offerId": "65322607CA04A12",
            "editionName": "VIPe 4 Adobe Acrobat DC Standard für Enterprise (Multi European Languages)"
        },
        {
            "offerId": "65324935CA02A12",
            "editionName": "VIP 2 Adobe After Effects Pro für Teams (EU English)"
        },
        {
            "offerId": "65304522CA01A12",
            "editionName": "VIP 1 Adobe Acrobat Pro für Teams (EU English)"
        },
        {
            "offerId": "65322723CA01A12",
            "editionName": "VIPe 1 Adobe Technical Suite für Enterprise (EU Englisch)"
        },
        {
            "offerId": "65324857CA03A12",
            "editionName": "VIPe 3 Adobe Photoshop Pro für Enterprise (Multi European Languages)"
        },
        {
            "offerId": "65324883CA03012",
            "editionName": "Promo VIP 3 Dreamweaver Pro für Teams (EU English)"
        },
        {
            "offerId": "65324867CA01012",
            "editionName": "Promo VIP 1 Photoshop Pro for teams (EU English)"
        },
        {
            "offerId": "65322443CA12A12",
            "editionName": "VIP 12 Select 3 Year Commit Creative Cloud for Enterprise All Apps (Multi EU Languages)"
        },
        {
            "offerId": "65324884CA03A12",
            "editionName": "VIP 3 Adobe Dreamweaver Pro für Teams (Multi European Languages)"
        },
        {
            "offerId": "65324771CA03012",
            "editionName": "Promo VIP 3 Lightroom Classic Pro for teams (Multi EU Languages)"
        },
        {
            "offerId": "65322519CA04A12",
            "editionName": "VIPe 4 Adobe Fresco für Enterprise (Multi European Languages)"
        },
        {
            "offerId": "65305369CA03A12",
            "editionName": "VIP 3 Select Adobe Premiere Pro für Teams (EU English)"
        },
        {
            "offerId": "65304840CA02A12",
            "editionName": "VIP 2 Select Adobe Creative Cloud für Teams All Apps mit 10 Adobe Stock Standard Assets (EU English)"
        },
        {
            "offerId": "65305247CA04A12",
            "editionName": "VIP 4 Select Adobe Stock für Teams Large 750 Standard Assets (EU English)"
        },
        {
            "offerId": "65322648CA12A12",
            "editionName": "VIP 12 Select 3 Year Commit Acrobat Pro for Enterprise (Multi EU Languages)"
        },
        {
            "offerId": "65304918CA02A12",
            "editionName": "VIP 2 Select Adobe Dreamweaver für Teams (Multi European Languages)"
        },
        {
            "offerId": "65305183CA14A12",
            "editionName": "VIP 14 Select 3 Year Commit Illustrator for teams (Multi EU Languages)"
        },
        {
            "offerId": "65305063CA04A12",
            "editionName": "VIP 4 Select Adobe Fresco für Teams (Multi European Languages)"
        },
        {
            "offerId": "65324884CA01A12",
            "editionName": "VIP 1 Adobe Dreamweaver Pro für Teams (Multi European Languages)"
        },
        {
            "offerId": "65304484CA01A12",
            "editionName": "VIP 1 Adobe Audition für Teams (EU English)"
        },
        {
            "offerId": "65304754CA13A12",
            "editionName": "VIP 13 3 Year Commit Captivate for teams (Multi EU Languages)"
        },
        {
            "offerId": "65324826CA12A12",
            "editionName": "VIP 12 Select 3 Year Commit Illustrator Pro for Enterprise (Multi EU Languages)"
        },
        {
            "offerId": "65325078CA12012",
            "editionName": "VIP 12 3 Year Commit Creative Cloud Pro for Teams All Apps (Multi EU Languages)"
        },
        {
            "offerId": "65322725CA02A12",
            "editionName": "VIPe 2 Adobe Technical Suite für Enterprise (Multi European Languages)"
        },
        {
            "offerId": "65324897CA02A12",
            "editionName": "VIP 2 Adobe Audition Pro für Teams (EU English)"
        },
        {
            "offerId": "65322537CAT4A12",
            "editionName": "Tier 4 Acrobat Sign Solutions for Business, Azure (Multi EU Languages)"
        },
        {
            "offerId": "65324903CA03A12",
            "editionName": "VIP 3 Adobe InCopy Pro für Teams (EU English)"
        },
        {
            "offerId": "65322471CA04A12",
            "editionName": "VIPe 4 Adobe Premiere Pro für Enterprise (Multi European Languages)"
        },
        {
            "offerId": "65322656CA14A12",
            "editionName": "VIP 14 Select 3 Year Commit InDesign for Enterprise (Multi EU Languages)"
        },
        {
            "offerId": "65322444CA01A12",
            "editionName": "VIPe 1 Adobe Creative Cloud für Enterprise (EU English)"
        },
        {
            "offerId": "65322607CA13A12",
            "editionName": "VIP 13 Select 3 Year Commit Acrobat Standard DC for Enterprise (Multi EU Languages)"
        },
        {
            "offerId": "65324901CA02012",
            "editionName": "Promo VIP 2 Audition Pro for Teams (Multi EU Languages)"
        },
        {
            "offerId": "65305156CA13A12",
            "editionName": "VIP 13 Select 3 Year Commit Photoshop for teams (Multi EU Languages)"
        },
        {
            "offerId": "65304840CA04A12",
            "editionName": "VIP 4 Select Adobe Creative Cloud für Teams All Apps mit 10 Adobe Stock Standard Assets (EU English)"
        },
        {
            "offerId": "65322517CA04A12",
            "editionName": "VIPe 4 Adobe Fresco für Enterprise (EU English)"
        },
        {
            "offerId": "65317571CA03A12",
            "editionName": "VIP 3 Select Adobe Substance 3D Collection for Teams (EU English)"
        },
        {
            "offerId": "65324871CA04012",
            "editionName": "Promo VIP 4 Dimension Pro für Teams (Multi European Languages)"
        },
        {
            "offerId": "65322656CA13A12",
            "editionName": "VIP 13 Select 3 Year Commit InDesign for Enterprise (Multi EU Languages)"
        },
        {
            "offerId": "65305243CA14A12",
            "editionName": "VIP 14 3 Year Commit Adobe Stock for teams (Large) (Multi EU Languages)"
        },
        {
            "offerId": "65322443CA13A12",
            "editionName": "VIP 13 Select 3 Year Commit Creative Cloud for Enterprise All Apps (Multi EU Languages)"
        },
        {
            "offerId": "65322603CA02A12",
            "editionName": "VIPe 2 Acrobat DC Standard  für Enterprise (EU English)"
        },
        {
            "offerId": "65305343CA03A12",
            "editionName": "VIP 3 Select Adobe FrameMaker für Teams (Multi European Languages)"
        },
        {
            "offerId": "65322429CA14A12",
            "editionName": "VIPe 14 Adobe Audition for Enterprise (Multi EU Languages)"
        },
        {
            "offerId": "65305282CA03A12",
            "editionName": "VIP 3 Select Adobe Stock für Teams Medium 40 Standard Assets (EU English)"
        },
        {
            "offerId": "65324841CA04A12",
            "editionName": "VIPe 4 Adobe Dreamweaver Pro für Enterprise (EU English)"
        },
        {
            "offerId": "65322417CA01A12",
            "editionName": "VIPe 1 Adobe Illustrator für Enterprise (EU Englisch)"
        },
        {
            "offerId": "65322623CA03A12",
            "editionName": "VIPe 3 Adobe After Effects für Enterprise (EU English)"
        },
        {
            "offerId": "65322422CA12A12",
            "editionName": "VIP 12 Select 3 Year Commit Illustrator for Enterprise (Multi EU Languages)"
        },
        {
            "offerId": "65324883CA01A12",
            "editionName": "VIP 1 Adobe Dreamweaver Pro für Teams (EU English)"
        },
        {
            "offerId": "65324785CA03A12",
            "editionName": "VIP 3 Adobe Lightroom Classic Pro für Teams (EU English)"
        },
        {
            "offerId": "65305183CA12A12",
            "editionName": "VIP 12 Select 3 Year Commit Illustrator for teams (Multi EU Languages)"
        },
        {
            "offerId": "65324945CA03A12",
            "editionName": "VIPe 3 Adobe Premiere Pro - Pro für Enterprise (Multi European Languages)"
        },
        {
            "offerId": "65305156CA02A12",
            "editionName": "VIP 2 Select Adobe Photoshop für Teams (Multi European Languages)"
        },
        {
            "offerId": "65304754CA12A12",
            "editionName": "VIP 12 3 Year Commit Captivate for teams (Multi EU Languages)"
        },
        {
            "offerId": "65322710CA04A12",
            "editionName": "VIPe 4 Adobe Captivate für Enterprise (EU English)"
        },
        {
            "offerId": "65322708CA03A12",
            "editionName": "VIPe 3 Adobe Captivate für Enterprise (Multi European Languages)"
        },
        {
            "offerId": "65322558CA01A12",
            "editionName": "VIP 1 Acrobat Sign Solutions for Enterprise, AWS (Multi EU Languages)"
        },
        {
            "offerId": "65322434CAT2A12",
            "editionName": "Tier 2 Acrobat Sign Solutions for Enterprise, AWS (Multi EU Languages)"
        },
        {
            "offerId": "65324867CA04A12",
            "editionName": "VIP 4 Adobe Photoshop Pro für Teams (EU English)"
        },
        {
            "offerId": "65324911CA04012",
            "editionName": "Promo VIP 4 Adobe Illustrator Pro for Teams (EU English)"
        },
        {
            "offerId": "65305158CA03A12",
            "editionName": "VIP 3 Select Adobe Photoshop für Teams (EU English)"
        },
        {
            "offerId": "65304521CA13A12",
            "editionName": "VIP 13 Select 3 Year Commit Acrobat Pro for teams (Multi EU Languages)"
        },
        {
            "offerId": "65324867CA03A12",
            "editionName": "VIP 3 Adobe Photoshop Pro für Teams (EU English)"
        },
        {
            "offerId": "65322434CAT4A12",
            "editionName": "Tier 4 Acrobat Sign Solutions for Enterprise, AWS (Multi EU Languages)"
        },
        {
            "offerId": "65324933CA04A12",
            "editionName": "VIP 4 Adobe After Effects Pro für Teams (Multi European Languages)"
        },
        {
            "offerId": "65322638CA13A12",
            "editionName": "VIP 13 Select 3 Year Commit Photoshop for Enterprise (Multi EU Languages)"
        },
        {
            "offerId": "65305156CA01A12",
            "editionName": "VIP 1 Adobe Photoshop für Teams (Multi European Languages)"
        },
        {
            "offerId": "65322422CA04A12",
            "editionName": "VIPe 4 Adobe Illustrator für Enterprise (Multi European Languages)"
        },
        {
            "offerId": "65322472CA02A12",
            "editionName": "VIPe 2 Adobe Premiere Pro für Enterprise (EU English)"
        },
        {
            "offerId": "65324883CA02A12",
            "editionName": "VIP 2 Adobe Dreamweaver Pro für Teams (EU English)"
        },
        {
            "offerId": "65305410CA12A12",
            "editionName": "VIP 12 Select 3 Year Commit InDesign for teams (Multi EU Languages)"
        },
        {
            "offerId": "65305248CA12A12",
            "editionName": "VIP 12 3 Year Commit Adobe Stock for teams (Small) (Multi EU Languages)"
        },
        {
            "offerId": "65322648CA13A12",
            "editionName": "VIP 13 Select 3 Year Commit Acrobat Pro for Enterprise (Multi EU Languages)"
        },
        {
            "offerId": "65305118CA14A12",
            "editionName": "VIP 14 Select 3 Year Commit Adobe XD for teams (Multi EU Languages)"
        },
        {
            "offerId": "65322713CA02A12",
            "editionName": "VIPe 2 Adobe Presenter Licensed for Enterprise (EU Englisch)"
        },
        {
            "offerId": "65322623CA02A12",
            "editionName": "VIPe 2 Adobe After Effects für Enterprise (EU English)"
        },
        {
            "offerId": "65323478CA03012",
            "editionName": "VIPe 3 Adobe Express für Enterprise (Multi European Languages)"
        },
        {
            "offerId": "65324802CA03012",
            "editionName": "Promo VIP 3 Select Animate / Flash Professional Pro for teams (Multi EU Languages)"
        },
        {
            "offerId": "65322534CAT3A12",
            "editionName": "Tier 3 Acrobat Sign Solutions for Enterprise, Azure (Multi EU Languages)"
        },
        {
            "offerId": "65322698CA02A12",
            "editionName": "VIPe 2 Adobe RoboHelp Office für Enterprise (Multi European Languages)"
        },
        {
            "offerId": "65322471CA14A12",
            "editionName": "VIPe 14 Adobe Premiere Pro for Enterprise (Multi EU Languages)"
        },
        {
            "offerId": "65305285CA14A12",
            "editionName": "VIP 14 3 Year Commit Adobe Stock for teams (Medium) (Multi EU Languages)"
        },
        {
            "offerId": "65304841CA14A12",
            "editionName": "VIP 14 Select 3 Year Commit Creative Cloud for teams All Apps with Adobe Stock (Multi EU Languages)"
        },
        {
            "offerId": "65317574CA12A12",
            "editionName": "VIP 12 Select 3 Year Commit Substance 3D Collection for Teams (Multi EU Languages)"
        },
        {
            "offerId": "65304607CA02A12",
            "editionName": "VIP 2 Select Adobe After Effects für Teams (EU English)"
        },
        {
            "offerId": "65305599CA13A12",
            "editionName": "VIP 13 Select 3 Year Commit Acrobat Sign for SMB (Multi EU Languages)"
        }
    ]

    const foundEdition = adobeEditions.find(edition => edition.offerId == offerId)
    if (foundEdition?.editionName != undefined) {
        return foundEdition.editionName
    } else {
        return false
    }
}

export default function Customer() {

    const { isLoggedIn, login, logout, user, relogin } = useAuth();
    const [adobeCustomerData, setAdobeCustomerData] = useState([])
    const [loading, setLoading] = useState(false);
    const [refreshInfo, setRefreshInfo] = useState(false);
    const [refreshSubscriptions, setRefreshSubscriptions] = useState(false);
    const [refreshOrders, setRefreshOrders] = useState(false);
    const [isInfoExpanded, setIsInfoExpanded] = useState(false)
    const [isSubscriptionExpanded, setIsSubscriptionExpanded] = useState(false)
    const [isOrderExpanded, setIsOrderExpanded] = useState(false)

    const { customerid } = useParams();

    useEffect(() => {
        const loadAdobeCustomerData = async () => {

            setLoading(true)
            let adobeCustomer = await getAdobeCustomer(user, customerid, true, true, true)

            setAdobeCustomerData(adobeCustomer)

            setIsInfoExpanded(false)
            setIsSubscriptionExpanded(false)
            setIsOrderExpanded(false)

            setLoading(false)

        }
        if (isLoggedIn.state == true) {

            loadAdobeCustomerData();
        }
    }, [isLoggedIn.state, user]);

    useEffect(() => {
        const updateAdobeCustomerData = async () => {

            if (refreshInfo || refreshSubscriptions || refreshOrders) {


                if (adobeCustomerData != []) {

                    let adobeCustomer = await getAdobeCustomer(user, customerid, refreshInfo, refreshSubscriptions, refreshOrders)
                    if (refreshInfo) {
                        adobeCustomerData.data.info = adobeCustomer?.data?.info
                    }
                    if (refreshSubscriptions) {
                        adobeCustomerData.data.subscriptions = adobeCustomer?.data?.subscriptions
                    }
                    if (refreshOrders) {
                        adobeCustomerData.data.orders = adobeCustomer?.data?.orders
                    }

                    setAdobeCustomerData(adobeCustomerData)
                    setRefreshInfo(false)
                    setRefreshSubscriptions(false)
                    setRefreshOrders(false)
                }
            }

        }
        updateAdobeCustomerData()
    }, [refreshInfo, refreshSubscriptions, refreshOrders])

    const jwtExpired = () => {
        relogin()
        return <section class="flex-grow">Loading Customers...</section>
    }

    return !isLoggedIn.state
        ? isLoggedIn.loading
            ? <section class="flex-grow"></section>
            : <Navigate replace to="/" />
        : loading
            ? <section class="flex-grow">Loading Customer...</section>
            :
            adobeCustomerData.state
                ?
                adobeCustomerData?.data?.info?.companyProfile?.companyName == undefined
                    ? <section class="w-11/12 xl:w-10/12 3xl:w-8/12 flex-grow mx-auto flex-col text-base text-center">
                        <section class="flex-grow">Customer not found</section>
                    </section>
                    :
                    <section class="w-11/12 xl:w-10/12 3xl:w-8/12 flex-grow mx-auto flex-col text-base text-left">
                        {/* Header */}


                        <section class="flex justify-start text-xl font-bold mt-4">
                            <section class="border-b border-[#DC0029] flex">
                                <section>{adobeCustomerData.data.info.customerId}</section>
                                <section class="mx-4">|</section>
                                <section>{adobeCustomerData?.data?.info?.companyProfile?.companyName}</section>
                            </section>
                        </section>

                        {/* Customer Informations */}
                        <section class="rounded-3xl bg-gray-200 my-4 border-4 border-gray-300">
                            <section class="px-8 py-4 mt-2">
                                <section class="flex justify-between">
                                    <section class="underline m-4 text-lg font-medium">Customer Informations </section>
                                    <section class="flex items-center">
                                        {isInfoExpanded
                                            ? <MdArrowDropDown class="m-4 cursor-pointer" size={35} onClick={() => setIsInfoExpanded(!isInfoExpanded)} />
                                            : <MdArrowDropUp class="m-4 cursor-pointer" size={35} onClick={() => setIsInfoExpanded(!isInfoExpanded)} />
                                        }
                                        <GrRefresh class="m-4 cursor-pointer" size={22} onClick={() => setRefreshInfo(true)} />
                                    </section>
                                </section>
                                <section class={`${isInfoExpanded ? "flex" : "hidden"} justify-center rounded-2xl bg-gray-100 p-4 truncate`}>
                                    <section>
                                        <section className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 2xl:grid-cols-8 3xl:grid-cols-10 font-medium gap-y-8 md:gap-x-4 lg:gap-x-16 p-4">
                                            <section class="flex-col">
                                                <section>Customer Id</section>
                                                <section class="font-normal">{adobeCustomerData.data.info.customerId.trim() ? adobeCustomerData.data.info.customerId : "-"}</section>
                                            </section>
                                            <section class="flex-col col-span-2">
                                                <section>Company Name</section>
                                                <section class="font-normal">{adobeCustomerData.data.info.companyProfile.companyName.trim() ? adobeCustomerData.data.info.companyProfile.companyName : "-"}</section>
                                            </section>
                                            <section class="flex-col">
                                                <section>Market Segment</section>
                                                <section class="font-normal">{adobeCustomerData.data.info.companyProfile.marketSegment.trim() ? adobeCustomerData.data.info.companyProfile.marketSegment : "-"}</section>
                                            </section>
                                            <section class="flex-col">
                                                <section>Country</section>
                                                <section class="font-normal">{adobeCustomerData.data.info.companyProfile.address.country.trim() ? adobeCustomerData.data.info.companyProfile.address.country : "-"}</section>
                                            </section>
                                            <section class="flex-col">
                                                <section>Region</section>
                                                <section class="font-normal">{adobeCustomerData.data.info.companyProfile.address.region.trim() ? adobeCustomerData.data.info.companyProfile.address.region : "-"}</section>
                                            </section>
                                            <section class="flex-col">
                                                <section>City</section>
                                                <section class="font-normal">{adobeCustomerData.data.info.companyProfile.address.city.trim() ? adobeCustomerData.data.info.companyProfile.address.city : "-"}</section>
                                            </section>
                                            <section class="flex-col col-span-2">
                                                <section>Address Line 1</section>
                                                <section class="font-normal">{adobeCustomerData.data.info.companyProfile.address.addressLine1.trim() ? adobeCustomerData.data.info.companyProfile.address.addressLine1 : "-"}</section>
                                            </section>
                                            <section class="flex-col">
                                                <section>Address Line 2</section>
                                                <section class="font-normal">{adobeCustomerData.data.info.companyProfile.address.addressLine2.trim() ? adobeCustomerData.data.info.companyProfile.address.addressLine2 : "-"}</section>
                                            </section>
                                            <section class="flex-col">
                                                <section>Postal Code</section>
                                                <section class="font-normal">{adobeCustomerData.data.info.companyProfile.address.postalCode.trim() ? adobeCustomerData.data.info.companyProfile.address.postalCode : "-"}</section>
                                            </section>
                                            <section class="flex-col">
                                                <section>Phone Number</section>
                                                <section class="font-normal">{adobeCustomerData.data.info.companyProfile.address.phoneNumber.trim() ? adobeCustomerData.data.info.companyProfile.address.phoneNumber : "-"}</section>
                                            </section>
                                            <section class="flex-col">
                                                <section>Creation Date</section>
                                                <section class="font-normal">{adobeCustomerData.data.info.creationDate.split("-")[2].substring(0, 2) + "." + adobeCustomerData.data.info.creationDate.split("-")[1] + "." + adobeCustomerData.data.info.creationDate.split("-")[0] + " " + adobeCustomerData.data.info.creationDate.split("T")[1].substring(0, 5) + "Uhr"}</section>
                                            </section>
                                            <section class="flex-col">
                                                <section>Coterm Date</section>
                                                <section class="font-normal">{adobeCustomerData.data.info.cotermDate.split("-")[2] + "." + adobeCustomerData.data.info.cotermDate.split("-")[1] + "." + adobeCustomerData.data.info.cotermDate.split("-")[0]}</section>
                                            </section>
                                        </section>
                                    </section>
                                </section>
                            </section>
                            <section class={`${isInfoExpanded ? "px-8 py-4 my-2" : "hidden"} `}>
                                <section class="underline m-4 font-medium">Contacts</section>
                                <section class="flex justify-start pl-8 rounded-2xl border-b bg-gray-100 p-4">
                                    <section>
                                        <section className="grid grid-cols-4 font-medium md:gap-x-16 lg:gap-x-32">
                                            <section className="">First Name</section>
                                            <section className="">Last Name</section>
                                            <section className="col-span-2">Email</section>
                                        </section>
                                        {adobeCustomerData.data.info.companyProfile.contacts.map((contact, index) => (
                                            <section key={index} className="grid grid-cols-4 truncate md:gap-x-16 lg:gap-x-32">
                                                <section className="">{contact.firstName || "-"}</section>
                                                <section className="">{contact.lastName || "-"}</section>
                                                <section className="col-span-2">{contact.email ? contact.email.toLowerCase() : "-"}</section>
                                            </section>
                                        ))}
                                    </section>
                                </section>
                            </section>
                            {/* Discounts */}
                            <section class={`${isInfoExpanded ? "px-8 py-4 my-2" : "hidden"} `}>
                                <section class="underline m-4 font-medium">Discounts</section>
                                <section class="flex justify-start rounded-2xl bg-gray-100 pl-8 p-4 truncate">
                                    {adobeCustomerData.data.info.discounts.length === 0
                                        ? "Keine Discounts"
                                        :
                                        <section>
                                            <section className="grid grid-cols-3 font-medium gap-x-4 sm:gap-x-8 md:gap-x-16 lg:gap-x-32 py-1">
                                                <section class="col-span-2">Ext Line Item Number</section>
                                                <section>OfferId</section>
                                            </section>
                                            {adobeCustomerData.data.info.discounts.map(discount =>
                                                <section className="grid grid-cols-3 font-medium gap-x-4 sm:gap-x-8 md:gap-x-16 lg:gap-x-32 py-1">
                                                    <section class="font-normal col-span-2">{discount.offerType ? discount.offerType : "-"}</section>
                                                    <section class="font-normal">{discount.level ? discount.level : "-"}</section>
                                                </section>
                                            )}
                                        </section>
                                    }
                                </section>
                            </section>
                            {/* Benefits */}
                            <section class={`${isInfoExpanded ? "px-8 py-4 my-2" : "hidden"} `}>
                                <section class="underline m-4 font-medium">Benefits</section>
                                <section class="flex justify-start rounded-2xl bg-gray-100 pl-8 p-4 truncate">
                                    <section>
                                        {adobeCustomerData.data.info.benefits.length === 0
                                            ? "Keine Benefits"
                                            :
                                            adobeCustomerData.data.info.benefits?.map((benefit, index) =>
                                                <section class={`${index == 0 ? "" : "border-t-4 border-gray-300"} grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-6 font-medium gap-y-8 gap-x-16 lg:gap-x-32 p-4`}>
                                                    <section class="flex-col">
                                                        <section>Type</section>
                                                        <section class="font-normal">{benefit.type ? benefit.type : "-"}</section>
                                                    </section>
                                                    <section class="flex-col col-span-2">
                                                        <section>Recommitment Request</section>
                                                        <section class="font-normal">{benefit.recommitmentRequest ? benefit.recommitmentRequest : "-"}</section>
                                                    </section>
                                                    {benefit.commitment != null &&
                                                        <section class="flex-col">
                                                            <section>Status</section>
                                                            <section class="font-normal">{benefit.commitment.status ? benefit.commitment.status : "-"}</section>
                                                        </section>
                                                    }
                                                    {benefit.commitmentRequest != null &&
                                                        <section class="flex-col">
                                                            <section>Status</section>
                                                            <section class="font-normal">{benefit.commitmentRequest.status ? benefit.commitmentRequest.status : "-"}</section>
                                                        </section>
                                                    }

                                                    {benefit.commitment != null &&
                                                        <section class="flex-col">
                                                            <section>Offer Type</section>
                                                            {benefit.commitment.minimumQuantities.map(minQuant =>
                                                                <section class="font-normal">{minQuant.offerType ? minQuant.offerType : "-"}</section>
                                                            )}
                                                        </section>
                                                    }

                                                    {benefit.commitment != null &&
                                                        <section class="flex-col">
                                                            <section>Quantity</section>
                                                            {benefit.commitment.minimumQuantities.map(minQuant =>
                                                                <section class="font-normal">{minQuant.quantity ? minQuant.quantity : "-"}</section>
                                                            )}
                                                        </section>
                                                    }
                                                    {benefit.commitment != null &&
                                                        <section class="flex-col">
                                                            <section>Start Date</section>
                                                            <section class="font-normal">{benefit.commitment.startDate ? benefit.commitment.startDate.split("-")[2] + "." + benefit.commitment.startDate.split("-")[1] + "." + benefit.commitment.startDate.split("-")[0] : "-"}</section>
                                                        </section>
                                                    }
                                                    {benefit.commitment != null &&
                                                        <section class="flex-col">
                                                            <section>End Date</section>
                                                            <section class="font-normal">{benefit.commitment.endDate ? benefit.commitment.endDate.split("-")[2] + "." + benefit.commitment.endDate.split("-")[1] + "." + benefit.commitment.endDate.split("-")[0] : "-"}</section>
                                                        </section>
                                                    }
                                                </section>
                                            )
                                        }
                                    </section>
                                </section>
                            </section>
                        </section>



                        {/* Subscriptions */}
                        <section class="rounded-3xl bg-gray-200 my-4 mt-12 border-4 border-gray-300">
                            <section class="px-8 py-4 my-2">
                                <section class="flex justify-between">
                                    <section class="underline m-4 text-lg font-medium">Subscriptions</section>
                                    <section class="flex items-center">
                                        {isSubscriptionExpanded
                                            ? <MdArrowDropDown class="m-4 cursor-pointer" size={35} onClick={() => setIsSubscriptionExpanded(!isSubscriptionExpanded)} />
                                            : <MdArrowDropUp class="m-4 cursor-pointer" size={35} onClick={() => setIsSubscriptionExpanded(!isSubscriptionExpanded)} />
                                        }
                                        <GrRefresh class="m-4 cursor-pointer" size={22} onClick={() => setRefreshSubscriptions(true)} />
                                    </section>
                                </section>
                                <section class={`${isSubscriptionExpanded ? "flex" : "hidden"} justify-center rounded-2xl bg-gray-100 p-4 truncate`}>
                                    {adobeCustomerData.data.subscriptions.items.length === 0
                                        ? "Keine Subscriptions"
                                        :
                                        <section>
                                            {adobeCustomerData.data.subscriptions?.items.map((sub, index) =>
                                                <section class={`${index == 0 ? "" : "border-t-4 border-gray-300"} grid grid-cols-2  md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 2xl:grid-cols-9 3xl:grid-cols-10 gap-y-8 gap-x-16 lg:gap-x-32 p-4 font-medium bg-gray-100`}>
                                                    <section class="flex-col col-span-2">
                                                        <section>Subscription Id</section>
                                                        <section class="font-normal">{sub.subscriptionId ? sub.subscriptionId : "-"}</section>
                                                    </section>
                                                    <section class="flex-col">
                                                        <section>Quantity</section>
                                                        <section class="font-normal">{sub.currentQuantity ? sub.currentQuantity : "-"}</section>
                                                    </section>
                                                    <section class="flex-col">
                                                        <section>Status</section>
                                                        <section class="font-normal">{sub.status ? sub.status : "-"}</section>
                                                    </section>
                                                    <section class="flex-col col-span-2">
                                                        <section>Offer Id</section>
                                                        <section class="font-normal">{sub.offerId ? sub.offerId : "-"}</section>
                                                    </section>
                                                    <section class="flex-col col-span-2">
                                                        <section>Edition Name</section>
                                                        <section class="font-normal">{sub.offerId ? getEditionName(sub.offerId) == false ? sub.offerId : getEditionName(sub.offerId) : "-"}</section>
                                                    </section>
                                                    <section class="flex-col col-span-2">
                                                        <section>Creation Date (UTC)</section>
                                                        <section class="font-normal">{sub.creationDate ? sub.creationDate.split("-")[2].substring(0, 2) + "." + sub.creationDate.split("-")[1] + "." + sub.creationDate.split("-")[0] + " " + sub.creationDate.split("T")[1].substring(0, 5) + "Uhr" : "-"}</section>
                                                    </section>
                                                    <section class="flex-col col-span-2">
                                                        <section>Renewal Date</section>
                                                        <section class="font-normal">{sub.renewalDate ? sub.renewalDate.split("-")[2] + "." + sub.renewalDate.split("-")[1] + "." + sub.renewalDate.split("-")[0] : "-"}</section>
                                                    </section>

                                                    <section class="flex-col col-span-2">
                                                        <section>Auto Renewal Enabled</section>
                                                        <section class="font-normal">{`${sub.autoRenewal.enabled}`}</section>
                                                    </section>
                                                    <section class="flex-col col-span-2">
                                                        <section>Auto Renewal Quantity</section>
                                                        <section class="font-normal">{sub.autoRenewal.renewalQuantity ? sub.autoRenewal.renewalQuantity : "-"}</section>
                                                    </section>
                                                </section>
                                            )}
                                        </section>
                                    }
                                </section>
                            </section>
                        </section>

                        {/* Orders */}
                        <section class="rounded-3xl bg-gray-200 my-4 mt-12 border-4 border-gray-300">
                            <section class="px-8 py-4 my-2">
                                <section class="flex justify-between">
                                    <section class="underline m-4 text-lg font-medium">Orders</section>
                                    <section class="flex items-center">
                                        {isOrderExpanded
                                            ? <MdArrowDropDown class="m-4 cursor-pointer" size={35} onClick={() => setIsOrderExpanded(!isOrderExpanded)} />
                                            : <MdArrowDropUp class="m-4 cursor-pointer" size={35} onClick={() => setIsOrderExpanded(!isOrderExpanded)} />
                                        }
                                        <GrRefresh class="m-4 cursor-pointer" size={22} onClick={() => setRefreshOrders(true)} />
                                    </section>
                                </section>
                                <section class={`${isOrderExpanded ? "flex" : "hidden"} justify-center rounded-2xl bg-gray-100 p-4 truncate`}>
                                    {adobeCustomerData.data.subscriptions.items.length === 0
                                        ? "Keine Orders"
                                        :
                                        <section>
                                            {adobeCustomerData.data.orders?.items.map((order, index) =>
                                                <section class={`${index == 0 ? "" : "border-t-4 border-gray-300"} font-medium bg-gray-100 p-4`}>
                                                    <section class=" grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 2xl:grid-cols-6 gap-y-8 gap-x-16 lg:gap-x-32 p-4">
                                                        <section class="flex-col">
                                                            <section>Order Id</section>
                                                            <section class="font-normal">{order.orderId ? order.orderId : "-"}</section>
                                                        </section>
                                                        <section class="flex-col col-span-2">
                                                            <section>External ReferenceId Id</section>
                                                            <section class="font-normal">{order.externalReferenceId ? order.externalReferenceId : "-"}</section>
                                                        </section>
                                                        <section class="flex-col">
                                                            <section>Order Type</section>
                                                            <section class="font-normal">{order.orderType ? order.orderType : "-"}</section>
                                                        </section>
                                                        <section class="flex-col">
                                                            <section>Status</section>
                                                            <section class="font-normal">{order.status ? order.status : "-"}</section>
                                                        </section>
                                                        <section class="flex-col">
                                                            <section>Currency Code</section>
                                                            <section class="font-normal">{order.currencyCode ? order.currencyCode : "-"}</section>
                                                        </section>
                                                        <section class="flex-col col-span-2">
                                                            <section>Creation Date (UTC)</section>
                                                            <section class="font-normal">{order.creationDate ? order.creationDate.split("-")[2].substring(0, 2) + "." + order.creationDate.split("-")[1] + "." + order.creationDate.split("-")[0] + " " + order.creationDate.split("T")[1].substring(0, 5) + "Uhr" : "-"}</section>
                                                        </section>
                                                    </section>

                                                    {order.lineItems.map((lineItem, index) =>
                                                        <section class={`${index == 0 ? "border-gray-300" : "border-gray-200"} border-t-2 grid grid-cols-2 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-6 2xl:grid-cols-7 4xl:grid-cols-9 gap-y-8 gap-x-16 lg:gap-x-32 p-4`}>
                                                            <section class="flex-col col-span-2">
                                                                <section>Ext Line Item Number</section>
                                                                <section class="font-normal">{lineItem.extLineItemNumber ? lineItem.extLineItemNumber : "-"}</section>
                                                            </section>
                                                            <section class="flex-col col-span-2">
                                                                <section>Offer Id</section>
                                                                <section class="font-normal">{lineItem.offerId ? lineItem.offerId : "-"}</section>
                                                            </section>
                                                            <section class="flex-col col-span-4">
                                                                <section>Edition Name</section>
                                                                <section class="font-normal">{lineItem.offerId ? getEditionName(lineItem.offerId) == false ? lineItem.offerId : getEditionName(lineItem.offerId) : "-"}</section>
                                                            </section>

                                                            <section class="flex-col col-span-2">
                                                                <section>Subscription Id</section>
                                                                <section class="font-normal">{lineItem.subscriptionId ? lineItem.subscriptionId : "-"}</section>
                                                            </section>
                                                            <section class="flex-col">
                                                                <section>Quantity</section>
                                                                <section class="font-normal">{lineItem.quantity ? lineItem.quantity : "-"}</section>
                                                            </section>
                                                            <section class="flex-col">
                                                                <Link to={`/Migration?customerId=${adobeCustomerData.data.info.customerId.trim()}&subscriptionId=${lineItem.subscriptionId}`} class="hover:text-[#DC0029]">
                                                                    <section className="">
                                                                        <a>Migration</a>
                                                                        <BiRightTopArrowCircle size={23} />
                                                                    </section>


                                                                </Link>
                                                            </section>
                                                        </section>
                                                    )}
                                                </section>
                                            )}
                                        </section>
                                    }
                                </section>
                            </section>
                        </section >

                    </section >

                : adobeCustomerData.message == "jwt expired"
                    ? jwtExpired()
                    : <section class="flex-grow">Failed Loading Customer... ({adobeCustomerData.message == "Invalid Customer" ? "Customer not found" : adobeCustomerData.message})</section>
}